import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams }                 from 'react-router-dom';
import Input                                      from '@mui/material/Input';
import InputAdornment                             from '@mui/material/InputAdornment';
import FormControl                                from '@mui/material/FormControl';
import FormHelperText                             from '@mui/material/FormHelperText';
import IconButton                                 from '@mui/material/IconButton';
import Button                                     from '@mui/material/Button';

import { AccountCircleIcon 
       , DoubleArrowLeftIcon
       , KeyIcon
    //    , PinIcon
       , VisibilityIcon
       , VisibilityOffIcon }                      from '../assets/icons/icons.js';
import { CustomMaxWidthTooltip }                  from '../components/CustomCSS/CustomCSS.tsx';
import { AuthContext }                            from '../contexts/authContext';
import { AppContext }                             from '../App.js';
import utils                                      from '../util/CommonUtilities.js'
import CircleProgress                             from '../components/CircleProgress/CircleProgress.tsx';
import appinfo                                    from '../appinfo';
import config                                     from '../config.js';
import moment                                     from 'moment';
import axios                                      from 'axios';
import './index.css';

const bDebug = config.DEBUG && false;

// const regexEmailValidator    = /^[a-z0-9]+([.\-_]?[a-z0-9]+)+@[a-z0-9]+([.\-_]?[a-z0-9]+)+\.[a-z]{2,}$/;
// const regexPasswordValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

const ForgotPassword = () => {
    
    const 
         [ ver, , month, day ] = ( appinfo.VERSION || '' ).split('-')
        ,env = process.env.REACT_APP_ENV
        ,navigate    = useNavigate()
        ,authContext = useContext(AuthContext)
        ,{ set_oNotifyOptions ,selectedLogo ,selectedLogoText, appName } = useContext(AppContext)
        ,{ email, code } = useParams()
        ,sHelperPswAccordant = 'Password non conforme'
        ,sHelperPswEqual     = 'Le due password non coincidono'
    ;

    const
         [ loading,              setLoading               ] = useState(false)
        ,[ sEmail,               set_sEmail               ] = useState( email ? email : "" )
        ,[ emailCheck,           set_emailCheck           ] = useState(false)
        ,[ password,             setPassword              ] = useState("")
        ,[ passwordCheck,        set_PasswordCheck        ] = useState(false)
        ,[ showPassword,         setShowPassword          ] = useState(false)
        ,[ passwordConfirm,      setPasswordConfirm       ] = useState("")
        ,[ passwordConfirmCheck, set_PasswordConfirmCheck ] = useState(false)
        ,[ showPasswordConfirm,  setShowPasswordConfirm   ] = useState(false)
        ,[ bBtnEnabled,          set_bBtnEnabled          ] = useState(false)
    ;

    let DATABASE_SERVER = '';

    useEffect(()=>{
        document.title = appName + ' | FORGOT PASSWORD';
    },[]);

    useEffect(()=>{
        set_emailCheck(utils.regexEmailValidator.test(sEmail));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[sEmail]);

    useEffect(()=>{
        set_PasswordCheck(utils.regexPasswordValidator.test(password));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[password]);

    useEffect(()=>{
        set_PasswordConfirmCheck(utils.regexPasswordValidator.test(passwordConfirm) );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[passwordConfirm]);

    const isErrorFormat = ( param, field ) => {
        let retVal = true;
        if ( param !== "") {
            if ( field === 'psw' ) {
                retVal = !passwordCheck;
            } else if ( field === 'confirm') {
                if ( password === passwordConfirm ) {
                    retVal = !passwordConfirmCheck ;
                }
            } else if ( field === 'email' ) {
                retVal = !emailCheck;
            } else {
                return false;
            }
        } else {
            retVal = false;
        }
        return retVal;
    };

    const myKeyPress = (e) => {
        bDebug && console.log('MyKeyPress: ', e.key);
        bDebug && console.log(e);
        if (e.key === 'Enter') {
            e.preventDefault();
            if ( bBtnEnabled ) {
                handleSubmit();
            }
        }
    };

    const handleSubmit = async (event, action) => {
        setLoading(true);
        if ( !code ) {
            
            try {
                await authContext.sendCode(sEmail);
                set_oNotifyOptions({ message: 'Email inviata correttamente, controlla la tua casella di posta', severity:'success', timeout: 4 });
            } catch (err) {
                console.error('ERR 162: Error in ForgotPassword (sendCode): ', err);
                set_oNotifyOptions({ message: 'Errore nel reset password, prego riprovare', severity:'error' });
            }
            
        } else {
            try {
                // Cognito
                await authContext.forgotPassword(sEmail, code, password);
                
                // DB
                const response = await axios.post(
                    config.API_URL + '/forgot-password'
                    // , JSON.stringify({ newPassword : password, email }) !!! Alternativa di passaggio params con axios.post
                    , null
                    , {
                        headers: {
                              'Accept': 'application/json'
                            , 'Authorization': 'no-auth'
                        },
                        params  : { newPassword : password, sEmail }
                    }
                )
                if (response.status === 200 ) {
                    set_oNotifyOptions({ message: 'Password aggiornata', severity:'success', timeout: 4 });
                    navigate('/');
                } else {
                    console.error(`ERR 164: interrogazione a /forgot-password effettuata, errore nella risposta`);
                    set_oNotifyOptions({ message: 'Errore nel reset password, prego riprovare', severity:'error' });
                }
            } catch (err) {
                console.error('ERR 163: errore in ForgotPassword ( authContext.forgotPassword o "/forgot-password" )', err);
                const bInvalidCode = (err.message === 'Invalid code provided, please request a code again.') 
                                  || (err.message === 'Invalid verification code provided, please try again.')
                set_oNotifyOptions({ message:  bInvalidCode
                                             ? 'Il link non è valido, ripetere la procedura di '
                                             : err.message
                                   , severity: 'error'
                                   , link:     bInvalidCode
                                             ? <a href='https://ilsole24ore.dis24.systrategy.com/forgot'>Reset Password.</a> 
                                             : <></> });
            }
        }
        setLoading(false);
        
    };

    useEffect(() => {
        bDebug && console.log('bStepWithCode: ', code);
        if(!code) {
            bDebug && console.log('emailCheck controlButton: ', emailCheck);
            set_bBtnEnabled( emailCheck );
        } else {
            set_bBtnEnabled( ( sEmail && emailCheck ) && ( code.length ) && ( password && passwordCheck ) && ( passwordConfirm && passwordConfirmCheck ) && ( password === passwordConfirm ) );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sEmail, emailCheck, password, passwordCheck, passwordConfirm, passwordConfirmCheck ]);

    const handleClickSignIn = (event) => {
	    event.preventDefault();
	    navigate('/login',{replace:false});
    };

    return (
        <div className="app-login-container" onKeyDown={ myKeyPress }>
            <div className={"login " + ( code ? "forgotPassword" : "" ) }>
                <div className={ "login-outer-container " + env }>
                    { loading && <CircleProgress /> }
                    <div className="background-with-logo"></div>
                    <div className="background-with-logo-top-dx"></div>
                    <div className="background-with-logo-top-dx-bis"></div>
                    <div className="background-with-logo-bot-dx"></div>
                    <div className="background-with-logo-bot-dx-bis"></div>

                    <div id="login-form" className="login-container">

                        <div className="side left login-content">
                            <h2 className='maiuscoletto'>
                                {
                                    !code
                                    ? "Richiedi Reset Password"
                                    : "Conferma Reset Password"
                                }
                            </h2>
                            {/* <div className="header-product-name top-bottom-section">
                                <img
                                    draggable   ="false"
                                    src         ={ selectedLogoText }
                                    className   ={ "logotext " }
                                    alt         ="Digital Analyzer"
                                />
                            </div> */}
                            
                            <div className="user-psw-container">
                                
                                <div className="forgot-credentials-link" onClick={handleClickSignIn}><DoubleArrowLeftIcon className="lightgrey" /><span>Ritorna all'autenticazione</span></div>
                            
                                <div className="">
                                    <FormControl className="login-credentials-wrapper top">
                                        <Input
                                            name            ="email"
                                            value           ={ sEmail }
                                            placeholder     ="Email"
                                            spellCheck      ={ false }
                                            onChange        ={ e => { set_sEmail(e.target.value); } }
                                            error           ={ isErrorFormat( sEmail, 'email' ) }
                                            startAdornment  = {
                                                <InputAdornment position="start">
                                                    <AccountCircleIcon />
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="standard-password-helper-text" className='helperText' >{ sEmail && !emailCheck ? 'Email non conforme' : '' }</FormHelperText>
                                    </FormControl>
                                </div>
                                {
                                    !code && 
                                        <span className='left-message' >
                                                  Verrà inviata una email all'indirizzo indicato
                                            <br/>contenente il link per completare il reset.
                                            <br/>Il link ha validità di 1 ora.
                                        </span>
                                }
                                {
                                    code &&
                                        <span className='criteriPass grey'>Password minimo 8 caratteri, 1 maiuscolo, 1 minuscolo, <br />1 numero, 1 simbolo</span>
                                }
                                {
                                    code && <>
                                        <div className="">
                                            <FormControl className="login-credentials-wrapper">
                                                <Input
                                                    name            ="password"
                                                    id              ="password"
                                                    placeholder     ="Nuova Password"
                                                    type            ={ showPassword ? 'text' : 'password' }
                                                    value           ={ password }
                                                    onChange        ={ e => { setPassword(e.target.value); } }
                                                    error           ={ isErrorFormat( password, 'psw' ) }
                                                    spellCheck      ={ false }
                                                    startAdornment  ={
                                                        <InputAdornment position="start">
                                                            <KeyIcon />
                                                        </InputAdornment>
                                                    }
                                                    endAdornment    ={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label ="toggle password visibility"
                                                                onClick    ={ e => { setShowPassword(!showPassword); } }
                                                            >
                                                                { showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/> }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <FormHelperText id="standard-password-helper-text" className='helperText' >{ password && !passwordCheck ? sHelperPswAccordant : '' }</FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div className="">
                                            <FormControl className="login-credentials-wrapper">
                                                <Input
                                                    name            ="confirm-password"
                                                    id              ="confirm-password"
                                                    placeholder     ="Conferma Password"
                                                    type            ={ showPasswordConfirm ? 'text' : 'password' }
                                                    value           ={ passwordConfirm }
                                                    onChange        ={ e => { setPasswordConfirm(e.target.value); } }
                                                    error           ={ isErrorFormat( passwordConfirm, 'confirm') }
                                                    spellCheck      ={ false }
                                                    startAdornment  ={
                                                        <InputAdornment position="start">
                                                            <KeyIcon />
                                                        </InputAdornment>
                                                    }
                                                    endAdornment    ={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label ="toggle password visibility"
                                                                onClick    ={ e => { setShowPasswordConfirm(!showPasswordConfirm); } }
                                                            >
                                                                { showPasswordConfirm ? <VisibilityIcon/> : <VisibilityOffIcon/> }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <FormHelperText id="standard-password-helper-text" className='helperText' >
                                                    {
                                                        ( passwordConfirm && !passwordConfirmCheck ) ?
                                                            sHelperPswAccordant
                                                            : (passwordConfirm && ( passwordConfirm !== password ) ) ?
                                                                sHelperPswEqual
                                                                : ''
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                        </div>
                                    </>
                                }

                                <div className="button-wrapper">
                                    <Button
                                        type        = "default"
                                        className   ={ "text-uppercase login-btn" }
                                        onClick     ={ handleSubmit }
                                        disabled    ={ !bBtnEnabled }
                                    >
                                        {
                                            // 10/10/2024 "Reset Password" => "Invia Email" by Maurizio Lanzi
                                            !code ? 'Invia Email' : 'Conferma Reset Password'
                                        }
                                    </Button>
                                </div>
                        
                            </div>
                        
                            <div className="footer-info top-bottom-section">
                                <a href="https://www.systrategy.it" target="_blank" rel="noreferrer">
                                    <img
                                        src         ="/systrategy_logo.svg"
                                        alt         ="Systrategy"
                                        draggable   ="false"
                                        className   ="systrategy-logo"
                                    />
                                </a>
                                <CustomMaxWidthTooltip 
                                    title={ <React.Fragment>Guida PDF per per il Reset della Password</React.Fragment> }
                                    placement ="bottom"
                                    arrow 
                                >
                                    <a className='link-pdf' href='/assets/pdf/DIS24_Procedura_per_Reset_Password.pdf' target='_blank'>Guida per il Reset Password</a>
                                </CustomMaxWidthTooltip>
                            </div>
                        </div>

                        <div className='side right only-logo'>
                            
                            <div className="info-top info-top-bottom">
                                <h1 className={ 'environment ' + env }>{ ( env || 'unknown' ).toUpperCase() }</h1>
                            </div>
                            
                            <img draggable="false" className='logo-lg' src={selectedLogo} alt="Digital Analyzer"/>
                    
                            <div className="info-bottom info-top-bottom">
                                <div className={ 'environment ' + env }>
                                    <span className="version-number">{
                                        ver + ' - ' + ( moment(day + '-' + month, 'DD-MM').format('D MMM')  )
                                    }</span>
                                    <span className="database-info">{ DATABASE_SERVER || 'NO DATABASE' }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ForgotPassword;
