import React, { useState, useEffect, Fragment } from 'react';
import { Button }                   from '@mui/material';
import { ResponsiveLine }           from '@nivo/line';
import { ResponsiveBar  }           from '@nivo/bar';
import { ResponsivePie  }           from '@nivo/pie';
import { Axes }                     from "@nivo/axes";
import { computeXYScalesForSeries } from "@nivo/scales";
import { useTooltip }               from "@nivo/tooltip";
import { line }                     from "d3-shape";
import utils                        from '../../util/CommonUtilities'; // Roobeek'./CommonUtilities';
import { LegendHideIcon
       , LegendShowIcon
       , MixBubbleChartIcon
       , MixLineChartIcon
       , FullScreenOffIcon
       , FullScreenOnIcon }         from '../../assets/icons/icons.js'; // '@mui/icons-material/Edit';
import './Graph.css';

const separator = '●';
const bDebug    = false;


export default function Graph({ oGraphContent = {}, className, aoGraphLegends, oMarkerLine }) {
    
    const
         [ data         , set_data         ] = useState([])
        ,[ asX          , set_asX          ] = useState([])
        ,[ asY          , set_asY          ] = useState([])
        ,[ asZ          , set_asZ          ] = useState([])
        ,[ asYmirror    , set_asYmirror    ] = useState([])
        ,[ sChartType   , set_sChartType   ] = useState('')
        ,[ bUpdateGraph , set_bUpdateGraph ] = useState(null)
        ,[ oMinMax      , set_oMinMax      ] = useState({ nMin: 0 , nMax: 0 })
        ,[ sLineMode    ,set_sLineMode     ] = useState('line') // 'area' : 'circle' : 'line' State per indicare il tipo di grafico da visualizzare in mix-chart-mode
        ,[ bShowLegend  ,set_bShowLegend   ] = useState(true)
        
        ,sAppendFormat   = oGraphContent.sAppendFormat || ''
        ,formatNum0dec   = v => utils.formatNumberWithOptions(v, { nOuputDecimals: 0 }) + sAppendFormat
        ,formatNum1dec   = v => utils.formatNumberWithOptions(v, { nOuputDecimals: 1 }) + sAppendFormat
        ,format0or1      = ( ( ( oMinMax.nMax - oMinMax.nMin ) < 10 ) ? formatNum1dec : formatNum0dec )
        ,sAppendFormatLineMix   = ' ' + oGraphContent.sAppendFormatLineMix || ''
        ,formatNum0decLineMix   = v => utils.formatNumberWithOptions(v, { nOuputDecimals: 0 }) + sAppendFormatLineMix
        ,formatNum1decLineMix   = v => utils.formatNumberWithOptions(v, { nOuputDecimals: 1 }) + sAppendFormatLineMix
        ,format0or1LineMix      = ( ( ( oMinMax.nMax - oMinMax.nMin ) < 10 ) ? formatNum1decLineMix : formatNum0decLineMix )
        ,isLine          = ( sChartType === 'line' )
        ,isBar           = ( sChartType === 'bar'  )
        ,isPie           = ( sChartType === 'pie'  )
        ,isMix           = ( sChartType === 'mix')
        ,sChartMode      = oGraphContent.sChartMode      || 'grouped'   // 'grouped' o 'stacked'
        ,bLegendButton   = oGraphContent.bLegendButton   || false // Parametro per mostrare il relativo bottone
        ,bLineModeButton = oGraphContent.bLineModeButton || false // Parametro per mostrare il relativo bottone
        ,bSelectLineLabel= oGraphContent.bSelectLineLabel|| false // Parametro per mostrare il relativo bottone
        ,fSelectLine     = (bSelectLineLabel && oGraphContent.selectLine) || (() => {})
        ,bFullModeButton = oGraphContent.bFullModeButton || false // Parametro per mostrare il relativo bottone
        ,sFullMode       = oGraphContent.sFullMode       || '' // Parametro per mostrare il relativo bottone
        ,fFullMode       = (bFullModeButton && oGraphContent.fFullMode)   || (() => {})

     // ,Roobeek         = [ '#f47171', '#b3f471', '#a6e7db', '#b371f4', '#f4f471', '#71f4b3', '#7171f4', '#f471b3', '#f4b371', '#71f471', '#71b3f4', '#f471f4']
        ,aNivoColors     = [ '#E8C1A0', '#ff9a88', '#f3e573', '#b3f471', '#79d4c5', '#a6e7db', '#00000000' ]
        ,aLineMixColors  = [ '#555555', '#DA338A', '#7171f4', '#ebb556', '#00000000' ]

        ,sChartColors    = oGraphContent.sChartColors    || aNivoColors    // { scheme: 'category10' }
        ,asLineColor     = oGraphContent.asLineColor     || aLineMixColors
        ,nRotationLabelX = ( oGraphContent.nRotationLabelX === 0 ) ? 0 : ( oGraphContent.nRotationLabelX || -45 )
        
        ,MAXLABELLENGTH  = oGraphContent.nMAXLABELLENGTH || 18
        ,nPieceLength    = ~~( ( MAXLABELLENGTH - 3 ) / 2 )
        ,fsCompressText  = (s) => {
            const s1 = ( ( s === 0 ? 0 : ( s || '' ) ) + '' );
            if ( s1.length < MAXLABELLENGTH ) {
                return s1;
            } else {
                const s2 = s1.replaceAll(separator, separator.trim() );
                return s2.slice(0,nPieceLength) + '...' + s2.slice(-nPieceLength);
            }
        }
    ;
        
    const Line = ({ bars, xScale, innerWidth, innerHeight, tooltip }) => {
        
        bars = bars.slice(0, data.length);

        let createObjScale = () => {
            let a = [];
            for (let e = 0; e < data.length; e++) {
                const o = data[e];
                for (let i = 0; i < asYmirror.length; i++) {
                    a.push({  x: o[asX[0]], y: o[asYmirror[i]] });
                }
            }
            return a;
        }

        const scale = computeXYScalesForSeries(
            [
              {
                id: "only",
                data: createObjScale()
              }
            ],
            { type: "linear" },
            { type: "linear" },
            innerWidth,
            innerHeight
        );

        const tip = useTooltip();
      
        function renderTip(e, idx) {
            return tip.showTooltipFromEvent(
              fTooltipFormat(data[idx]),
              e
            );
        }

        const generaLinea = (s, idx) => {
            const lineGenerator = line()
            .x( (bar) => ( bar.x + ( bar.width / 2 ) ) )
            .y( (bar) => scale.yScale( bar.data.data[s] || 1 ) );

            return <Fragment key={ s }>
                {   sLineMode === 'line' && 
                    <path
                      key    = { `l${s}` }
                      d      = { lineGenerator(bars) }
                      stroke = { asLineColor[idx] }
                      style  = {{ pointerEvents: "none" , strokeWidth: 2 }}
                      fill   = "none"
                    />
                }
                {bars.map((bar, i) => ( 
                  <circle
                    key    = { `c${idx}-${i}`}
                    cx     = { bar.x + (bar.width / 2) }
                    cy     = { scale.yScale(bar.data.data[s] || 1)}
                    r      = { 4 }
                    style  = {{ pointerEvents: "none" }}
                    stroke = { asLineColor[idx] + '98' }
                    fill   = "white"
                  />
                ))}
            </Fragment>
        }
      
        return ( 
          <Fragment>
            <Axes
              yScale={scale.yScale}
              xScale={xScale}
              width={innerWidth}
              height={innerHeight}
              right={{
                ticksPosition: "after",
                format: format0or1LineMix 
              }}
            />
            {
                asYmirror.map((s, idx) => generaLinea(s, idx))
            }
            {bars.map((bar, idx) => <rect
                key={'t'+idx}
                x={bar.x}
                y={0}
                height={innerHeight}
                width={bar.width}
                fill="transparent"
                onMouseEnter={(e) => renderTip(e, idx)}
                onMouseMove={(e) => renderTip(e, idx)}
                onMouseLeave={tip.hideTooltip}
              />
            )}
          </Fragment>
        );
    };
    
    const fTooltipFormat = v => {
        /* {
            "Periodo": "giu 2024",
            "Impessions Erogate": 330257,
            "Invenduto": 29446688,
            "Revenue": 2231,
            "V+I": 29776945
            }
        } */
        // console.log(v)
        
        const tTooltiptipRow = (s, index, chart) => {
            let bChartType = chart === 'line-mix';
            return (<tr key={ s } className="graph-tooltip-row">
                    <td><div style={{ 
                            backgroundColor: !bChartType ? sChartColors[ index ] : asLineColor[index]  
                           ,width:"10px" ,height:"10px"
                           ,border:"1px solid #555555"
                        }}></div></td>
                    <td className="graph-tooltip-label"><div>{ s }:</div></td>
                    <td className="graph-tooltip-value"><div>
                        { bChartType ? sAppendFormatLineMix + ' ' : '' }
                        { isMix ? 
                            formatNum0dec(v[s]) 
                        : isBar ?
                            formatNum0dec(v.data[s])
                        : formatNum0dec(data[index].data.find((o) => o.x === v.point.data.x).y)
                        }
                    </div></td>
            </tr>)
        };

        const
             xIndexValue    = ( ( isLine        ? v.point.data.xFormatted     :
                                  isBar         ? v.indexValue                :
                                  isPie         ? v.datum.label               :
                                  isMix         ? v[asX[0]]                   : // Non cancellare serve per formattare il primo valore
                                   '' ) || '' ) + ''
            
            ,color          = ( ( isLine        ? v.point.serieColor          :
                                  isBar         ? v.color                     :
                                  isPie         ? v.datum.color               :
                                   '' ) || '' ) + ''
            
            ,id             = ( ( isLine        ? v.point.serieId             :
                                  isBar         ? v.id                        :
                                  isPie         ? asY[0]                      :
                                  '' ) || '' ) + ''
            
            ,value          = (   isLine        ? v.point.data.yFormatted     :
                                  isBar         ? format0or1( v.value       ) :
                                  isPie         ? format0or1( v.datum.value ) :
                                   0 ) || 0
            
            ,as_xFormatted  = ( ( typeof xIndexValue === 'string' ) ? xIndexValue : '' ).split(separator)
        ;
        
        return <table className="graph-tooltip">
            <tbody>
                {
                    asX[0].split(separator).map(
                        ( sElementX, n ) => <tr key={sElementX+n} className="graph-tooltip-row">
                            <td></td><td className="graph-tooltip-label"><div>{ sElementX }:</div></td><td className="graph-tooltip-value"><div>{ as_xFormatted[n] }</div></td>
                        </tr>
                    )
                }
                {
                    isMix ? <>
                            { asY.map( (s, index) => tTooltiptipRow(s, index, 'bar-mix')) }
                            { asYmirror.map( (s, index) => tTooltiptipRow(s, index, 'line-mix')) }
                        </>
                 : !isPie ? <>{ asY.map( (s, index) => tTooltiptipRow(s, index, 'bar')) }</>
                 : <tr className="graph-tooltip-row">
                        <td><div style={{ backgroundColor: color ,width:"10px" ,height:"10px" ,border:"1px solid #555555" }}></div></td>
                        <td className="graph-tooltip-label"><div>{ id }: </div></td>
                        <td className="graph-tooltip-value"><div>{ value }</div></td>
                    </tr>
                }
            </tbody>
        </table>
    }
    
    const CustomSymbolShape = ({ x, y, size, fill }) => (
        <rect
            x={x}
            y={y}
            // transform={`rotate(0 ${13/2} ${13/2})`}
            fill={fill}
            strokeWidth={1}
            stroke={'#555555'}
            width={size}
            height={size}
            style={{ pointerEvents: 'none' }}
        />
    );

    // Roobeek : [{
        //  dataFrom           : 'keys'
        // ,anchor             : 'top-right'
        // ,direction          : 'column'
        // ,justify            : false
        // ,translateX         : -50
        // ,translateY         : -50
        // ,itemsSpacing       : 0
        // ,itemDirection      : 'left-to-right'
        // ,itemWidth          : 80
        // ,itemHeight         : 20
        // ,itemOpacity        : 1
        // ,symbolSize         : 12
        // ,symbolShape        : 'square'
        // ,symbolBorderColor  : 'rgba(0, 0, 0, 0.5)'
        // ,toggleSerie        : true
    // }];
    const legends = (
        aoGraphLegends 
            ? aoGraphLegends
            : [
                {
                     data           : bShowLegend ? asY.map((y, i) => ({ id: y, label: y, color: sChartColors[i] })) : []
                    ,anchor         : isMix ? 'top-left' : 'top'
                    ,direction      : 'row'
                    ,justify        : false
                    ,translateX     : 0
                    ,translateY     : -35
                    ,itemsSpacing   : 30
                    ,itemDirection  : 'left-to-right'
                    ,itemWidth      : 135 // Questo sarebbe meglio condizionarlo in base alla stringa
                    ,itemHeight     : 14
                    ,itemOpacity    : 1
                    ,symbolSize     : 13
                    ,symbolShape    : CustomSymbolShape
                    ,itemBackground : '#ffffffcc'
                }
            ]
    );

    const legendsMix = (
      aoGraphLegends 
        ? aoGraphLegends
        : [
            {
               data           : bShowLegend ? asYmirror.map((y, i) => ({ id: y, label: y, color: asLineColor[i] })) : []
              ,anchor         : 'top-right'
              ,direction      : 'row'
              ,justify        : false
              ,translateX     : 50
              ,translateY     : -35
              ,itemsSpacing   : 25
              ,itemDirection  : 'left-to-right'
              ,itemWidth      : 125 // Questo sarebbe meglio condizionarlo in base alla stringa
              ,itemHeight     : 14
              ,itemOpacity    : 1
              ,symbolSize     : 13
              ,symbolShape    : CustomSymbolShape
              ,itemBackground : '#ffffffcc'
            }
        ]
    );
    
    const margins = (
        oGraphContent?.margins
            ? oGraphContent?.margins
            : ( isBar || isMix ) ? { top: 35,  right: 70,  bottom: 70,  left: 75  }
            : isLine             ? { top: 35,  right: 25,  bottom: 70,  left: 75  }
                                 : { top: 100, right: 100, bottom: 100, left: 100 }
    );
    
    useEffect( () => {
        set_sChartType(  oGraphContent.sChartType  );
        set_bUpdateGraph(oGraphContent.bUpdateGraph);
    }, [ oGraphContent ]);
    
    useEffect(() => {
        
        if ( sChartType && oGraphContent?.aoRows?.length ) {
            
            let 
                 aoRows             = [ ...( oGraphContent.aoRows     || [] ) ]
                ,oDrillDown         = { ...( oGraphContent.oDrillDown || {} ) }
                ,asElementX         = [ ...( oGraphContent.asElementX || [] ) ]  // dimensioni es. [ 'DATA_RIF' ]
                ,asElementY         = [ ...( oGraphContent.asElementY || [] ) ]  // misure     es. [ 'BACINO' ,'BACINO_MM_FAST' ,'BACINO_MM_SLOW' ]
                ,asElementZ         = [ ...( oGraphContent.asElementZ || [] ) ]  // dimensione pivottata es. [ 'YEAR' ]
                ,asElementYmirror   = [ ...( oGraphContent.asElementYmirror || [] ) ] // Misura Sovrapposta del Mix di grafici es. [ 'R' ]
                ,oMappingField      = { ...( oGraphContent.oMappingField ) }
                ,nSliceRecord       = oGraphContent?.nSliceRecord || 100 
                // ,asAllDate          = [ ...(oGraphContent.aAllDate || [ 'DATA_RIF' ] ) ]
                ,oGrandTotal        = {}
                ,aoValues
            ;
            
            bDebug && console.table( 'asElementX',       asElementX );
            bDebug && console.table( 'asElementY',       asElementY );
            bDebug && console.table( 'asElementZ',       asElementZ );
            bDebug && console.table( 'asElementYmirror', asElementYmirror );
            
            if ( oGraphContent.excludeTOT ) {
                oGrandTotal         = aoRows.shift(); // rimuovo il primo record (il GRAND TOTAL)
                // TODO visualizzare il GRAND TOTAL da qualche parte
            }
            
            if ( aoRows.length > nSliceRecord ) {
                aoRows              = aoRows.slice( 0,nSliceRecord ); // solo i primi 'nSliceRecord' valori
            }
            
            let nMin = 0, nMax = 0;
            const mapElement        = sElement => ( oMappingField[sElement] || {} ).description || sElement;
            
            if ( typeof oGraphContent.nMin === 'number' ) {
                nMin = oGraphContent.nMin;
            }
            
            if ( typeof oGraphContent.nMax === 'number' ) {
                nMax = oGraphContent.nMax;
            }
            
            aoRows = aoRows.map( oRow => {
                
                let oRowModified    = {};
                let sNewKey         = asElementX.length > 1 ? asElementX.join( separator ) : '';
                
                // se il minimo è una funzione
                if ( typeof oGraphContent.nMin === 'function' ) {
                    nMin = Math.min( ( nMin || 0 ),  oGraphContent.nMin( oRow ) );
                }
                
                // se il massimo è una funzione
                if ( typeof oGraphContent.nMax === 'function' ) {
                    nMax = Math.max( ( nMax || 0 ),  oGraphContent.nMax( oRow ) );
                }
                
                // se uno tra nMin e nMax non viene passato in oGraphContent (o non vengono passati entrambi)
                if ( ( oGraphContent.nMin == null ) || ( oGraphContent.nMax == null ) ) {

                    let asTempElementY = [...asElementY];
                    if ( oGraphContent.sMinMaxCustomField ) {
                        asTempElementY.push( oGraphContent.sMinMaxCustomField );
                    }
                    
                    // ciclo sui campi dell'asse Y
                    for ( const sY of asTempElementY ) {
                        
                        // se il minimo non è stato passato
                        if ( oGraphContent.nMin == null ) {
                            nMin = Math.min( ( nMin || 0 ), ( oRow[sY] || 0 ) );
                        }
                        
                        // se il massimo non è stato passato
                        if ( oGraphContent.nMax == null ) {
                            nMax = Math.max( ( nMax || 0 ), ( oRow[sY] || 0 ) );
                        }
                        
                    }
                }
                
                for ( let sKey in oRow ) {
                    
                    if ( oMappingField ) {
                        const sFilterDataType = ( oMappingField[sKey] || {} ).filterDataType;
                        oRowModified[ mapElement(sKey) ] = !sFilterDataType ? oRow[sKey] : utils.convertDataType( oRow[sKey], sFilterDataType );
                    }
                    
                }
                
                if ( sNewKey ) {
                    oRowModified[  sNewKey ] = asElementX.map( sElementX => ( oRow[ sElementX ] || oDrillDown[ sElementX ] || '' ) ).join( separator );
                    asElementX = [ sNewKey ]; // resetto l'array di elementi che andranno sull'asse X in un solo campo concatenato
                }
                
                return oRowModified;
                
            });
            nMax = nMax + ~~( nMax * 7 / 100 );
            nMin = ( nMin === 0 ) ? 0 : ( nMin + ~~( nMin * 7 / 100 ) );
            set_oMinMax({ nMin, nMax });
            
            
            if ( oMappingField ) {
                
                asElementX       = asElementX.map(       mapElement );
                asElementY       = asElementY.map(       mapElement );
                asElementZ       = asElementZ.map(       mapElement );
                asElementYmirror = asElementYmirror.map( mapElement );
                oDrillDown       = Object.keys(oDrillDown).reduce( ( o, k ) => ({ ...o, [mapElement(k)]: oDrillDown[k] }), {});
                
            }
            
            bDebug && console.log('sChartType: ',sChartType);

            if ( isLine || isBar || isMix ) {
                
                // [ 'SPOT_YEAR 2020' ]: { PROG_ID: 1 ,CHANNEL_DESC: 'CNN' ,SPOT_YEAR: '2020' ,SPOT_LENGTH: 213456 }
                
                const sElementX = asElementX[0];
                
                if ( asElementZ && asElementZ.length ) {
                    
                    let
                         oRecordPrec         = {}
                        ,aoRowsPivoted       = []
                        ,nRowsPivotedCounter = -1
                        ,oElementY           = {}
                    ;
                    
                    for ( const oRecord of aoRows ) {
                        
                        if ( oRecord.PROG_ID !== oRecordPrec.PROG_ID ) {
                            
                            oRecordPrec = { ...oRecord };
                            nRowsPivotedCounter++;
                            
                        } 
                        
                        for ( const sElementZ of asElementZ ) {
                            
                            for ( const sElementY of asElementY ) {
                                
                                const sKey = ( oRecord[ sElementZ ] ? ( oRecord[ sElementZ ] + ' ' ) : '' ) + sElementY;
                                
                                aoRowsPivoted[ nRowsPivotedCounter ] = {
                                     ...( aoRowsPivoted[ nRowsPivotedCounter ] || oRecord || {} )      // se stesso se esiste
                                    ,[ sKey ] : oRecord[ sElementY ]  // [ 'SPOT_YEAR 2020' ]: 213456 }
                                };
                                
                                oElementY[ sKey ] = true;
                                delete aoRowsPivoted[ nRowsPivotedCounter ][ sElementY ];
                                
                            }
                            
                            delete aoRowsPivoted[ nRowsPivotedCounter ][ sElementZ ];
                            
                        }
                        
                    }
                    
                    asElementY      = Object.keys(oElementY);
    
    
                    if ( sChartType === 'bar' ) {
        
                        /* esempio bar
                            [
                                {
                                     "country"  : "AD"
                                     
                                    ,"burger"   : 132
                                    ,"sandwich" : 125
                                    ,"kebab"    : 256
                                }
                            ]
                        */
        
                        aoValues        = aoRowsPivoted;
                        asElementZ      = [];
        
                    } else {
    
                        aoValues        = asElementY.map(
                            sElementY => ({
                                 id: sElementY
                                 // ,color: 'red'
                                ,data: aoRowsPivoted.map( oRow => ({ x: oRow[sElementX] || '-' ,y: oRow[sElementY] || 0 }) )
                            })
                        );
        
                    }
                    
                } else {
                    
                    if ( isBar || isMix ) {
        
                        /* esempio bar
                            [
                                {
                                     "country"  : "AD"
                                     
                                    ,"burger"   : 132
                                    ,"sandwich" : 125
                                    ,"kebab"    : 256
                                }
                            ]
                        */
                        aoValues        = aoRows;                        
        
                    } else if ( isLine ) {
                        /* risultato finale:
                            [
                                {
                                    "id"    : "japan"
                                    ,"data"  : [
                                                { "x": "plane"         ,"y": 91 }
                                                ,{ "x": "helicopter"    ,"y": 85 }
                                            ]
                                }
                            ]
                        */
                        aoValues        = asElementY.map(
                            sElementY => ({
                                 id: sElementY
                                 // ,color: 'red'
                                ,data: aoRows.map( oRow => ({ x: oRow[sElementX] || '-' ,y: oRow[sElementY] || 0 }) )
                            })
                        );
                        
                    }
                    
                }
                
            } else if ( isPie ) {
                
                /* esempio pie
                    [
                        {
                             "id"   : "python"
                            ,"label": "python"
                            ,"value": 90
                        }
                    ]
                */
                
                // const oFieldsMap = oGraphContent.oFieldsMap;        // es. { 'DATA_RIF': v => moment(v,'YYYYMMDD').format('ddd D MMM YYYY') }
                aoValues = aoRows.map( oRow => ({ id: oRow[asElementX[0]] ,label: oRow[asElementX[0]] ,value: oRow[asElementY[0]] }) );
                
            }
            
            bDebug && console.table( 'asElementX',       asElementX );
            bDebug && console.table( 'asElementY',       asElementY );
            bDebug && console.table( 'asElementZ',       asElementZ );
            bDebug && console.table( 'asElementYmirror', asElementYmirror );
            bDebug && console.table( 'aoValues',         aoValues );
            
            set_asX(       asElementX       || []);
            set_asY(       asElementY       || []);
            set_asZ(       asElementZ       || []);
            set_asYmirror( asElementYmirror || []);
            set_data(      aoValues         || []);
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sChartType, bUpdateGraph, sLineMode, bShowLegend ]);
    
  return <div className={ 'graph ' + className }>{
    
    ( data != null ) && ( data.length > 0 ) && (  

      ( isBar ) ? (

        <ResponsiveBar
          data                    ={data}
          keys                    ={asY}
          indexBy                 ={ asZ[0] || asX[0] || null }
          margin                  ={margins}
          padding                 ={0.35}
          innerPadding            ={2}
          groupMode               = { sChartMode }
          valueScale              ={{ type:   'linear' }}
          indexScale              ={{ type:   'band'   , round: true }}
          colors                  ={ sChartColors }
          borderWidth             ={0.5}
          borderColor             ={{ from:   'color'  , modifiers: [ [ 'darker', 0.5 ] ] }}
          axisTop                 ={null}
          axisRight               ={null}
          axisBottom              ={{
               tickSize        : 5
              ,tickPadding     : 5
              ,tickRotation    : nRotationLabelX
              ,format          : fsCompressText
          }}
          axisLeft                ={{
               tickSize        : 5
              ,tickPadding     : 5
              ,tickRotation    : 0
              ,format          : format0or1
          }}
          labelSkipWidth          ={12}
          labelSkipHeight         ={12}
          labelTextColor          ={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
          legends                 ={legends}
          animate                 ={false}
          motionStiffness         ={90}
          motionDamping           ={15}
          enableLabel             ={ oGraphContent.enableLabel }
          enableTotals            ={ false } // ={ sBarMode === 'stacked' ? true : false }
          label                   ={ oGraphContent.formattedLabelValue }
          tooltip                 ={fTooltipFormat}
          minValue                ={oMinMax.nMin}
          maxValue                ={oMinMax.nMax}
          markers                 ={[{
              axis              : 'y'
             ,value             : 0
             ,lineStyle         : {
                  stroke            : 'rgb(0, 0, 0)'
                 ,strokeWidth       : 1
             }
          }]}
        />
                
      ) : ( isLine ) ? (
        // sliceTooltip    ={ v => `${ v.slice.points[0].data.xFormatted } : ${ v.slice.points[0].serieId } ${ v.slice.points[0].data.yFormatted }` }
        // enableSlices    ={'x'}
        <ResponsiveLine
          data          ={data}
          margin        ={margins}
          enableGridX   ={false}
          animate       ={false}
          enableSlices  ={false}
        //   enableSlices  ={'x'}
          xScale        ={{ type: 'point' }}
          xFormat       ={ v => v }
          tooltip       ={fTooltipFormat}
          yScale        ={{
             type           : 'linear'
            ,min            : oMinMax.nMin
            ,max            : oMinMax.nMax
            ,stacked        : sChartMode === 'stacked'
            ,reverse        : false
          }}
          yFormat       ={ format0or1 }
          axisTop       ={null}
          axisRight     ={null}
          axisBottom    ={{
             orient         : 'bottom'
            ,tickSize       : 5
            ,tickPadding    : 5
            ,tickRotation   : nRotationLabelX
            ,format         : fsCompressText
          }}
          axisLeft                ={{
             orient         : 'left'
            ,tickSize       : 5
            ,tickPadding    : 5
            ,tickRotation   : 0
            ,format         : format0or1
          }}
          pointSize          ={4}
          pointColor         ={ 'white' }
          pointBorderWidth   ={1}
          pointBorderColor   ={'black'}
          pointLabel         ={ d => `${d.x} : ${d.y}`}
          useMesh            ={true}
          legends            ={legends}
          enablePointLabel   ={false}
          pointLabelYOffset  ={0}
          enableArea         ={ sLineMode === 'area' }
          areaOpacity        ={1}
          colors             ={ sChartColors }
          markers            ={ oMarkerLine || false }
          layers             ={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
        />
                
      ) : ( isMix ) ? (
        <>
          <span className='group-option-wrapper'>
            <span className='left-options'>
              { bLegendButton && <Button 
                className={`modeButton`} 
                title={`${bShowLegend ? 'Nascondi Legenda' : 'Mostra Legenda'}`}
                variant="outlined" 
                onClick={ () => set_bShowLegend(!bShowLegend) } 
                disableElevation>
                  { bShowLegend ? <LegendHideIcon /> : <LegendShowIcon /> } 
              </Button>}
              { bLineModeButton && <Button 
                className={`modeButton`} 
                title={`${sLineMode === 'line' ? 'Modalità Grafico a Punti' : 'Modalità Grafico a Linee'}`} 
                variant="outlined" 
                onClick={ () => set_sLineMode(sLineMode === 'line' ? 'circle' : 'line') } 
                disableElevation>
                  { sLineMode === 'line' ? <MixBubbleChartIcon /> : <MixLineChartIcon /> } 
              </Button>}
            </span>
            <span className='right-options'>
              { bSelectLineLabel && fSelectLine() }
              { bFullModeButton && <Button 
                className={`fullViewButton link myShadow`}
                title={`${sFullMode === 'fullGraph' ? 'Comprimi' : 'Espandi'}`} 
                variant="outlined" 
                onClick={ () => fFullMode('fullGraph') } 
                disableElevation>
                  { sFullMode === 'fullGraph' ? <FullScreenOffIcon /> : <FullScreenOnIcon /> } 
              </Button>}
            </span>
          </span>
          <ResponsiveBar
            data={data}
            keys             ={ asY }
            indexBy          ={ asZ[0] || asX[0] || null }
            margin           ={margins}
            padding          ={0.35}
            innerPadding     ={2}
            groupMode        = { sChartMode }
            valueScale       ={{ type:   'linear' }}
            indexScale       ={{ type:   'band'   , round: true }}
            colors           ={ sChartColors }
            borderWidth      ={0.5}
            borderColor      ={{ from:   'color'  , modifiers: [ [ 'darker', 0.5 ] ] }}
            axisTop          ={null}
            axisRight        ={null}
            axisBottom       ={{
               tickSize      : 5
              ,tickPadding   : 5
              ,tickRotation  : nRotationLabelX
              ,format        : fsCompressText
            }}
            axisLeft                ={{
               tickSize      : 5
              ,tickPadding   : 5
              ,tickRotation  : 0
              ,format        : format0or1
            }}
            labelSkipWidth   ={12}
            labelSkipHeight  ={12}
            labelTextColor   ={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            legends          ={[
               ...legends
              ,...legendsMix
            ]}
            animate          ={false}
            motionStiffness  ={90}
            motionDamping    ={15}
            enableLabel      ={ oGraphContent.enableLabel }
            enableTotals     ={ false }
            label            ={ oGraphContent.formattedLabelValue }
            tooltip          ={fTooltipFormat}
            minValue         ={oMinMax.nMin}
            maxValue         ={oMinMax.nMax}
            layers={["grid", "axes", "bars", Line, "markers", "legends"/*, LineLegend */]}
          />
        </>
      ) : ( isPie ) ? (
        <ResponsivePie 
          data                    ={data}
          margin                  ={margins}
          padAngle                ={0}
          cornerRadius            ={5}
          colors                  ={{ scheme: 'set2' }}
          borderWidth             ={0}
          borderColor             ={{from: 'color', modifiers: [['darker', 0.2]]}}
          valueFormat             ={formatNum0dec}
          enableSliceLabels       ={false}
          sliceLabelsSkipAngle    ={10}
          sliceLabelsTextColor    ="#333333"
          innerRadius             ={0.2}
          animate                 ={false}
          enableArcLabels         ={true}
          arcLabelsSkipAngle      ={18.1}
          arcLabelsRadiusOffset   ={0.65}
          arcLinkLabelsSkipAngle  ={6}
          arcLinkLabel            ={ v => v.id }
          tooltip                 ={fTooltipFormat}
        />
      ) : <></>
    )
  }</div>
}