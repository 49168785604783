import React, {
     useState
    ,useEffect
    ,useContext
}                       from 'react';
import { useNavigate }  from 'react-router-dom';
import config           from '../../config.js';
import axios            from 'axios';
import moment           from 'moment';
import Box              from '@mui/material/Box';
import TabContext       from '@mui/lab/TabContext';
import TabPanel         from '@mui/lab/TabPanel';
import Select           from '@mui/material/Select';
import MenuItem         from '@mui/material/MenuItem';
import Autocomplete     from '@mui/material/Autocomplete';
import Tooltip          from '@mui/material/Tooltip';
import IconButton       from '@mui/material/IconButton';
import Button           from '@mui/material/Button';
import {
     CustomCssTab
    ,CustomCssTabList
}                                 from '../../components/CustomCSS/CustomCSS.tsx';

import './Settings.css'
import { AppContext }   from '../../App.js';
import { AuthContext }  from '../../contexts/authContext';
import { SimpleTable }  from '../../components/SimpleTable/SimpleTable';
import { PopupConfirm } from '../../components/PopupConfirm/PopupConfirm'
import CircleProgress   from '../../components/CircleProgress/CircleProgress.tsx';
import utils            from '../../util/CommonUtilities';
import {
     CheckIcon
    ,NotAllowedIcon
    ,FolderOffIcon
}                       from '../../assets/icons/icons';
const bDebug = config.DEBUG && false;

const Settings = () => {

    const
         isAdmin    = ( localStorage.getItem('flagAdmin') || '' ) === 'Y'
        ,isProd     = process.env.REACT_APP_ENV.includes('prod')
        ,auth       = useContext(AuthContext)
        ,{
             set_oNotifyOptions
            ,set_dLastUpdate
        } = useContext(AppContext)
        ,headers    = {
            'Accept'        : 'application/json',
            'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
        }
        ,navigate   = useNavigate()
    
        ,formatDate = (val) => utils.formatDateTime(val, { input: 'YYYYMMDDHHmmss', output: 'D MMM YYYY HH:mm', useTimezone: true })

        ,[ loading          ,setLoading          ] = useState(true)
        ,[ onReload         ,set_onReload        ] = useState(null)
        ,[ nValueTab        ,set_nValueTab       ] = useState('1')

        ,[ aoAlarms         ,set_aoAlarms        ] = useState([])
        ,[ aoAlarmsHeaders  ,set_aoAlarmsHeaders ] = useState([])
        
        ,[ sPopupType       ,set_sPopupType      ] = useState('')
        
        ,[ bShowDialog      ,set_bShowDialog     ] = useState(false)
        ,[ oDialogOptions   ,set_oDialogOptions  ] = useState({}) // { sTitle: '', content: null, sConfirmButton: '' }
    ;

    const reload = () => { setLoading(true); set_onReload( !onReload ); };

    const handleChangeTab = (event, newValue) => {
        set_nValueTab(newValue);
    };
    
    const toggleNotifica = ( message, severity, timeout ) => {
        setLoading(false);
        set_bShowDialog(false);
        set_oNotifyOptions({ message, severity,...( timeout && { timeout }) });
    };

    useEffect(() => {
        if ( !isAdmin ) {
            navigate('/errorPage403');
        }
        set_dLastUpdate('');
        setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        
        setLoading(true);
        // set_oNotifyOptions(null);
        
        isAdmin && ( async () => {
            try {
                const response = await axios.get( config.API_URL + '/get', { headers, params : { "getAlarms": true } } );
                if ( response.status === 200 ) {
                    const result = response.data;
                    
                    if ( result ) {
                        let aoRecords = result.aoRecords || [];
                        
                        const iconAttivo    = <div className='iconWrap'><CheckIcon className={"textGreen"}/></div>;
                        const iconDisattivo = <div className='iconWrap'><NotAllowedIcon className={"textRed"}/></div>;
                        
                        // const formatDate    = (val) => !val ? null : moment(val, 'YYYYMMDDHHmmss').format('DD MMM YYYY HH:mm');
                        set_aoAlarmsHeaders([
                             { name: 'FULL_NAME'                ,title: 'Nome'                  ,width: 190     ,tooltip: true }
                            ,{ name: 'USERNAME'                 ,title: 'Utente'                ,width: 375     ,tooltip: true        ,isUniqueKeyForRow: true }

                            ,{ name: 'AGENTE_COD'               ,title: 'Codice Agente'         ,width: 95   }
                            ,{ name: 'FLAG_ATTIVO'              ,title: 'Attivo'                ,width: 60      ,format: (val) => val === 'Y' ? iconAttivo : iconDisattivo
                                ,selectOptions: [
                                     { label: ' '           ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
                                    ,{ label: iconAttivo    ,value: 'Y' ,checkFunc: ( val, valToCheck ) => val.includes(valToCheck) }
                                    ,{ label: iconDisattivo ,value: 'N' ,checkFunc: ( val, valToCheck ) => val.includes(valToCheck) }
                                ]
                            }
                            ,{ name: 'DATA_INSERIMENTO'         ,title: 'Data Creazione'        ,width: 135     ,format: formatDate }
                            ,{ name: 'DATA_ULTIMA_MODIFICA'     ,title: 'Ultima Modifica'       ,width: 135     ,format: formatDate }
                            ,{ name: 'DATA_ULTIMA_LOGIN'        ,title: 'Ultima Connessione'    ,width: 135     ,format: formatDate }
                            ,{ name: 'DATA_CANCELLAZIONE'       ,title: 'Data Cancellazione'    ,width: 135     ,format: formatDate } /* FLAG_ATTIVO scatena il cambio di stato di DATA_CANCELLAZIONE */
                        ]);
                        set_aoAlarms(aoRecords);
                    }
                }
            } catch(err) {
                console.error('ERR 130: Failed GetAlarms: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
            }
            setLoading(false);
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);


    return (
        <div className="component-container alarms-settings">
            <div className="component-card">
                { loading ? <CircleProgress /> :
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={nValueTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomCssTabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                <CustomCssTab label="Tab 1" value="1" />
                   { !isProd && <CustomCssTab label="Tab 2" value="2" /> }
                            </CustomCssTabList>
                        </Box>
                        <TabPanel value="1" className="tabPanel elenco">
                            
                            <div className="component-options-wrapper">
                            {
                                loading ? <></> : <>{
                                    aoAlarms?.length && aoAlarmsHeaders?.length ? 
                                        <SimpleTable
                                            chiave            ={ 'ALARMS' }
                                            sTableDataType    ={ 'ALARMS' }
                                            aoRows            ={ aoAlarms }
                                            aoCols            ={ aoAlarmsHeaders }
                                            isEditable        ={ true }
                                            oSortOptions      ={ { isSortable: true } }
                                        />
                                    : <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp; Nessun risultato trovato </div>
                                }</>
                            }
                            </div>
                        </TabPanel>
                        <TabPanel value="2" className="tabPanel boh"></TabPanel>
                    </TabContext>
                </Box>
                }
            </div>
        </div>
    )
};

export default Settings;
