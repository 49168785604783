import './SimpleTable.css';
import React, { useEffect, useRef, useState } from 'react';
// import { Paper, Checkbox, MenuItem, Select} from '@material-ui/core';
import { DownLoadIcon ,EditIcon } from '../../assets/icons/icons.js';
import {
    Autocomplete ,
    Button
    ,Checkbox
    ,MenuItem
    ,Paper
    ,Select
    ,TextField
    ,Tooltip
} from '@mui/material';
import { ClearIcon }            from '@mui/x-date-pickers';
import PaginationTool           from '../PaginationTool/PaginationTool';
import utils                    from '../../util/CommonUtilities.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment }        from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker }           from '@mui/x-date-pickers/DatePicker';
import { itIT }                 from '@mui/x-date-pickers/locales';
import moment                   from 'moment';
import                               'moment/locale/it';
import InputAdornment           from '@mui/material/InputAdornment';
import IconButton               from '@mui/material/IconButton';

const bDebug             = false;
const removeSpecialChars = s => ( ['string','number'].includes(typeof s) ? s : '' ).toString().replace(/[^a-zA-Z0-9]/g, "");
let timeoutIdOnHover     = 0;
const env = process.env.REACT_APP_ENV;
const isDemo  = env.includes('demo');

const customLocale = {
    // Oppure, più diretto, puoi intervenire su "localeText" se vuoi cambiare l'etichetta "Clear"
    localeText: {
        ...itIT.localeText,
        clearButtonLabel: 'RESET', // Sovrascrivi la scritta del pulsante
        cancelButtonLabel: 'ANNULLA', // Sovrascrivi la scritta del pulsante
    },
};

function numeroCasualeDecimale( n = 1 ) {
    const
         valori = [ 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8 ]
        ,oggi   = new Date()
        ,giorno = oggi.getDate()
        ,mese   = oggi.getMonth()
        ,anno   = oggi.getFullYear()
        ,numero = anno * 10000 + mese * 100 + giorno + n
        ,indice = numero % valori.length
    ;
    return valori[indice];
}

// sortBy consente di ordinare un array di oggetti in base ad un array di proprietà (chiavi) scelte,
// specificando per ognuna se in ordine crescente o decrescente
const sortBy = ( arrayOfObjects, arrayOfProperties, arrayOfSortingType ) => {
    // console.log('arrayOfProperties: ', arrayOfProperties)
    // console.log('arrayOfSortingType: ', arrayOfSortingType)
    // se non è un array, lo converto in array ( per chiamare il sortBy con un solo parametro di tipo string )
    if ( !Array.isArray(arrayOfProperties) ) { arrayOfProperties = [ arrayOfProperties + '' ]; }

    let
        // toStr serve per avere stringhe simili da confrontare (i falsy vengono uniformati)
         toStr                = anyVar => ( anyVar || 0 ).toString()
        // uniformRes serve per uniformare i risultati del localeCompare ( -1 0 +1 )
        ,uniformRes           = ( res, sortType ) => ( 
            ( ( res < 0 ) ? -1 : ( res > 0 ? 1 : 0 ) ) * ( [ 'DESC', '-1' ].includes( ( sortType + '' ).toUpperCase() ) ? -1 : 1  )
        )
        // compare restituisce un valore uniformato per la comparazione di stringhe
        ,compare              = ( e1, e2, sortType ) => uniformRes(
              toStr(e1).localeCompare( toStr(e2), 'en', {numeric: true, sensitivity: 'base'} )
             ,sortType
        )
        // validateString controlla se è una stringa non vuota
        ,validateString       = str        => ( typeof str === 'string' && str !== '' ) ? str : ( 'err: "' + str + '"' )
        // validateProperty restituisce il valore di quella proprietà (eventualmente normalizzato a zero)
        ,validateProperty     = ( elToDebug, prop )  => {
            bDebug && console.assert(
                 typeof elToDebug[prop] !== 'undefined'
                ,'ERROR in sortBy: proprietà ' + prop + ' non trovata in'
                ,elToDebug
            );
            return elToDebug[prop] || 0;
        }
        // compareAllProperties restituisce ( -1 0 +1 ) in base al confronto di tutte le proprietà dei due oggetti
        ,compareAllProperties = ( obj1, obj2, props = [], sortTypes = [] ) => {
            let retVal = 0;
            for ( let nProp = 0; nProp < props.length; nProp++ ) {
                const property = validateString(props[nProp]);
                retVal         = retVal || compare(
                     validateProperty(obj1, property)
                    ,validateProperty(obj2, property)
                    ,sortTypes[nProp]
                );
            }
            return retVal;
        }
    ;
    // restituisce l'array di oggetto ordinato
    return [...arrayOfObjects].sort( ( a, b ) => compareAllProperties( a, b, arrayOfProperties, arrayOfSortingType ) );
    
};

const isIntKey = function( evt ) {
    
    const allowedKeys = [
        "Backspace",
        "Delete",
        "Tab",
        "ArrowLeft",
        "ArrowRight",
        "Home",
        "End",
        "Enter",
        "Escape",
    ];
    
    // Se rientra nei tasti speciali, ok
    if (allowedKeys.includes(evt.key)) {
        return;
    }
    
    // Se è una cifra, lascia passare; maxLength penserà al limite
    if (/^[0-9]$/.test(evt.key)) {
        return;
    }
    
    // Altrimenti blocca
    evt.preventDefault();
};

const clearOnCANCorBACKSPACE = ( evt, clearFunc ) => {
    if ( ['Backspace','Delete'].includes( evt.key ) ) {
        evt.preventDefault();
        clearFunc();
    }
};

// Quando il campo prende il focus, seleziona tutto il contenuto
const autoSelectOnFocus = (evt) => {
    evt.target.select();
};

/**
 * Componente SimpleTable
 *
 * Un componente React  configurabile per la visualizzazione di tabelle, con le seguenti funzionalità:
 * - Ordinamento delle righe (singolo o multi-colonna) in modalità ascendente/descendente.
 * - Filtraggio avanzato per colonne, con gestione differenziata di tipi di dato:
 *     - Testo:   supporta flag per case sensitivity, ricerca esatta/parziale e gestione dei caratteri speciali.
 *     - Numeri:  filtri che accettano operatori (<, >, =) e confronto numerico.
 *     - Date:    filtri tramite DatePicker con formattazione personalizzabile (inclusa l’opzione "from now").
 * - Selezione di righe tramite checkbox, con supporto per "seleziona tutto" e callback per singola riga.
 * - Evidenziazione (highlight) delle righe al click, con callback per gestire l’evidenziazione esclusiva.
 * - Modalità edit: visualizza un’icona edit per ogni riga se abilitata, con callback associato.
 * - Esportazione in CSV (se abilitata) con file naming dinamico in base alla data/ora.
 * - Gestione dei gruppi di colonne (intestazioni raggruppate) e supporto di tool per la paginazione tramite il componente PaginationTool.
 * - Impostazione dinamica dell’altezza del componente in base al container (con offset personalizzabile).
 *
 * @component
 * @param {Object}            props                       Proprietà del componente.
 * @param {string}            props.chiave                Chiave univoca per identificare il componente.
 * @param {string}            [props.className]           Classe CSS aggiuntiva per il wrapper.
 * @param {string}            props.sTableDataType        Tipo di dati della tabella (utilizzato per stili css specifici).
 * @param {Array<Object>}     [props.aoRows=[]]           Array di oggetti rappresentanti le righe (i dati) della tabella.
 * @param {Object}            [props.oRowOptions]         Opzioni per la gestione delle righe (es. callback per classi aggiuntive).
 * @param {Array<Object>}     props.aoCols                Array di colonne. Ogni oggetto colonna può contenere:
 *    - name:               (string)   Nome del campo.
 *    - key:                (string)   Identificatore univoco (viene generato se non specificato).
 *    - title:              (string)   Titolo da visualizzare nell’intestazione.
 *    - type:               (string)   Tipo di dato (es. 'Date', 'Number' oppure generico testo).
 *    - width               (string|number)  Larghezza della colonna.
 *    - format:             (function) Funzione per formattare il valore visualizzato.
 *    - formatExcel:        (function) Funzione per formattare il valore in fase di esportazione.
 *
 *    - dateOptions         (Object)   Opzioni per la formattazione delle date, che possono includere:
 *                              - input:  (string) Formato di input per il parsing.
 *                              - output: (string) Formato di output per la visualizzazione.
 *                              - fromNow:(boolean) Se true, mostra la data in formato relativo.
 *
 *    - selectOptions:      (Array)    Array di opzioni per il filtro a select, con { value, label, checkFunc }.
 *    - isUniqueKeyForRow:  (boolean)  Flag che indica se la colonna rappresenta la chiave unica della riga.
 *    - group:              (string)   Nome del gruppo di colonne per raggruppamento delle intestazioni.
 *    - additionalClass:    (function) Callback che restituisce eventuali classi CSS aggiuntive basate sul valore.
 *    - onHover:            (function) Callback per gestire il contenuto al passaggio del mouse.
 *    
 *    - showDiff            (boolean)  Se true, evidenzia la cella se il valore è uguale al valore della riga precedente.
 *    - isNum               (boolean)  Flag per indicare se il valore della colonna è numerico (usato in modalità demo).
 *    - onClickColHead      (function) Callback chiamato al click sull'intestazione della colonna.
 *    - headerTooltip       (string)   Testo del tooltip per l'intestazione della colonna.
 *    - notSortable         (boolean)  Se true, la colonna non può essere ordinata.
 *    - noFilter            (boolean)  Se true, il filtro per la colonna non viene visualizzato.
 *    - advancedTextFilters (boolean)  Se true, abilita filtri testuali avanzati (opzioni per case sensitivity, ricerca esatta e gestione dei caratteri speciali).
 *    - filterOriginalValue (boolean)  Se true, il filtraggio utilizza il valore originale anziché quello formattato.
 *    - noExport            (boolean)  Se true, la colonna non verrà esportata nel CSV.
 * @param {string}            [props.extraClasses]        Classi CSS extra da applicare al componente.
 * @param {boolean}           [props.noHeight]              Se true, la tabella non imposta un'altezza fissa basata sul container.
 * @param {boolean}           [props.noHeader]              Se true, le intestazioni della tabella non vengono visualizzate.
 * @param {boolean}           [props.noFilter]              Se true, la riga dei filtri non viene visualizzata.
 * 
 * @param {boolean}           [props.bOnlySelected]         Se true, vengono visualizzate solo le righe selezionate (secondo isCheckedFunction).
 * @param {function}          [props.isCheckedFunction]     Funzione che, data una riga, restituisce true se la riga è selezionata.
 * @param {function}          [props.fCheckAll]             Callback chiamato al cambio dello stato "seleziona tutto".
 * @param {function}          [props.fCheckOne]             Callback chiamato al cambio della checkbox di una singola riga. Riceve un oggetto { isChecked, oRow }.
 * @param {Object}            [props.oCheckOptions={}]      Opzioni per le checkbox, con:
 *    - isEnabled:          (boolean)  Abilita/disabilita l’uso delle checkbox.
 *    - isVisible:          (boolean)  Visualizza/nasconde la colonna delle checkbox.
 * 
 * @param {boolean}           [props.isHighlightable]       Se true, permette di evidenziare (highlight) le righe al click.
 * @param {function}          [props.isHighlightedFunction] Funzione che, data una riga, restituisce true se la riga è evidenziata.
 * @param {function}          [props.fHighlightOnlyOne]     Callback per evidenziare una sola riga; viene chiamata al click su una riga.
 * 
 * @param {boolean}           [props.isEditable]            Se true, abilita l'icona edit per ogni riga.
 * @param {function}          [props.editFunction]          Callback chiamato al click sull'icona edit. Riceve la riga da modificare.
 * 
 * @param {Object}            [props.oHeadersOptions={}]    Opzioni per le intestazioni della tabella (es. tooltip, breakline).
 * @param {function}          [props.exception=val => val]  Funzione per modificare il valore visualizzato nelle celle.
 * 
 * @param {function}          [props.fGetRecord]            Funzione per il recupero/aggiornamento dei dati, utilizzata in modalità sorting remoto.
 * @param {Object}            [props.oSortOptions={ isSortable: false, isMultiColSortable: false, oSort: null }]
 * Opzioni per l'ordinamento:
 *    - isSortable:         (boolean)  Abilita/disabilita il sorting.
 *    - isMultiColSortable: (boolean)  Consente l'ordinamento su più colonne contemporaneamente.
 *    - oSort:              (object)   Impostazioni di sorting attuali (es. { name, sort }).
 * 
 * @param {Object}            [props.oPaginationOption={ isPagination: false, oPag: {} }]
 * Opzioni per la paginazione:
 *    - isPagination:       (boolean)  Abilita/disabilita la paginazione.
 *    - oPag:               (object)   Informazioni per la paginazione (es. nTotRecord, pNumRecords, nFirstRecordPage, set_nFirstRecordPage).
 * 
 * @param {Object}            [props.oExportOption]         Opzioni per l'esportazione CSV:
 *    - sFileName:          (string)   Nome base del file esportato (default "export").
 *    - isExportable:       (boolean)  Abilita/disabilita l'opzione di esportazione (default true).
 * 
 * @param {number}            [props.offSetHeight=0]        Valore numerico da sommare all'altezza del container.
 * @param {boolean}           [props.randomizeOnlyNumbersForDemo=false] Se true, i valori numerici verranno randomizzati in modalità demo.
 *
 * @returns {JSX.Element} Elemento React che rappresenta la tabella.
 *
 * @example
 * // Esempio di utilizzo all'interno di un container:
 * const tableContainer = ({
 *     key, sTableType, sTableDataType, aoColumns, aoRows, setAoRows,
 *     bChecksVisible, bOnlySelectedEnabled, exception, noHeader, noFilter,
 *     isEditable, editFunction, isCheckedFunction, fCheckAll, fCheckOne
 * }) => {
 *     if (!aoRows || !aoRows.length) return null;
 *
 *     return (
 *         <SimpleTable
 *             chiave            = { key }
 *             sTableDataType    = { sTableDataType }
 *             aoRows            = { aoRows }
 *             aoCols            = { aoColumns }
 *
 *             noHeight          = { true }
 *             noHeader          = { noHeader }
 *             noFilter          = { noFilter }
 *             bOnlySelected     = { bOnlySelectedEnabled }
 *             
 *             oCheckOptions     = { { isEnabled: true, isVisible: (sTableType === 'checkable') && bChecksVisible } }
 *             isCheckedFunction = { isCheckedFunction }
 *             fCheckAll         = { bCheckAll => fCheckAll({ bCheckAll, aoRows, setAoRows }) }
 *             fCheckOne         = { ({ isChecked, oRow }) => fCheckOne({ isChecked, oRow, aoRows, setAoRows }) }
 *             
 *             isEditable        = { !bChecksVisible && isEditable }
 *             editFunction      = { isEditable && editFunction ? editFunction : () => {} }
 *             
 *             exception         = { exception }
 *         />
 *     );
 * };
 *
 * // Invocazione del container:
 * tableContainer({
 *     key:                    'users',
 *     sTableDataType:         'USERS',
 *     sTableType:             'checkable',
 *     aoColumns:              [...aoUsersColumns],
 *     aoRows:                 [...aoUsers],
 *     setAoRows:              set_aoUsers,
 *     bChecksVisible:         bUsersChecks,
 *     bOnlySelectedEnabled:   false,
 *     isEditable:             true,
 *     editFunction:           onClickEdit,
 *     isCheckedFunction:      isCheckedFunction,
 *     fCheckAll:              fCheckAll,
 *     fCheckOne:              fCheckOne
 * });
 */
export function SimpleTable({
    chiave, className,
    sTableDataType, aoRows = [], oRowOptions, aoCols, extraClasses, noHeight, noHeader, noFilter,  bOnlySelected,
    isCheckedFunction = ()=>{}, fCheckAll, fCheckOne, oCheckOptions = {}, // oCheckOptions = { isEnabled: true, isVisible: true }
    isHighlightable, isHighlightedFunction = ()=>{}, fHighlightOnlyOne,
    isEditable, editFunction,
    oHeadersOptions = {},
    exception = val=>val,
    fGetRecord,
    oSortOptions = { isSortable: false, isMultiColSortable: false, oSort: null },
    oPaginationOption = { isPagination: false, oPag: {} },  // nTotRecord: null, nRecordPerPage: null, nFirstRecordPage: null, set_nFirstRecordPage: null }, // functionToExecute === fSort
    oExportOption ,
    offSetHeight = 0,
    randomizeOnlyNumbersForDemo = false
}) {
    // l'abilitazione delle checkbox è possibile solo se gli elementi nelle righe hanno la proprietà "FLAG_SELECTED" ( 'Y' oppure 'N' )

    const isEmpty          = aoRows.length === 0;
    aoCols = aoCols.filter(Boolean);
    
    // PRE-ELABORAZIONE DELLE COLONNE
    for ( let n = 0; n < aoCols.length; n++ ) {
        let o = aoCols[n];
        
        // se non c'è la key, uso name come key
        if ( !o.key ) {
            o.key = o.name + '' + n;
        }
        
        // per il nuovo tipo di colonna "Date" non è previsto il format e deve essere ignorato
        if ( o?.type === 'Date' ) {
            if ( o?.format ) {
                o.format = undefined;
            }
        }
    }
    
    const asKeyColumnNames = ( aoCols.filter( oCol => oCol.isUniqueKeyForRow ) || {} ).map( oCol => oCol.name );
    const getUniqueKey     = ( oRow, asKeyColumnNames ) => {
        return asKeyColumnNames.reduce(
            ( sUniqueKey, sKeyColumnName ) => sUniqueKey + ( oRow[ sKeyColumnName ] || '' ),
            ''
        );
    };
    const bFiltersLimitReached = false; // ( aoRows.filter( isCheckedFunction ).length >= ( config.FILTERS_LIMIT || 50 ) );

    const { sFileName = 'export' , isExportable = true } = oExportOption || {};

    const
        [ oColumnFilters     ,set_oColumnFilters     ] = useState({})           // per ogni colonna mi segno il valore da filtrare
       ,[ aoFilteredRows     ,set_aoFilteredRows     ] = useState([...aoRows])  // array interno delle sole righe filtrate
       ,[ height             ,set_height             ] = useState(0)            // mantiene fissa l'altezza della finestra dopo il primo caricamento dei dati
       ,[ headGroupRowHeight ,set_headGroupRowHeight ] = useState(0)            // mantiene fissa l'altezza della riga dopo il primo caricamento dei dati
       ,[ headersRowHeight   ,set_headersRowHeight   ] = useState(0)            // mantiene fissa l'altezza della riga dopo il primo caricamento dei dati
       ,[ bSelectAll         ,set_bSelectAll         ] = useState(null)         // indica se devono essere selezionati tutti gli elementi
        // ATTENZIONE: importante che bSelectAll sia inizializzato a null e poi nella useEffect si verifichi che sia boolean
       ,[ coordHover         ,set_coordHover         ] = useState([])
       ,[ isDisabledExport   ,set_isDisabledExport   ] = useState(false)
       ,[ showDateRelative   ,set_showDateRelative   ] = useState(true)
    ;

    const paperContainer = useRef(null);
    const headGroupRow   = useRef(null);
    const headersRow     = useRef(null);
    const getValidTitle  = oCol => typeof(oCol.title) === 'string' ? oCol.title : oCol.headerTooltip;

    const exportCSV = () => {
        set_isDisabledExport(true);
        const aasDataRows = [
              aoCols.filter( oCol => oCol && !oCol.noExport ).map( getValidTitle )
            , ...aoFilteredRows.map( oRow => 
                aoCols
                    .filter( oCol => oCol && !oCol.noExport )
                    .map( ( oCol, nCol ) => {
                        const 
                            originalValue  = oRow[ oCol.name ]
                           ,valueToDisplay = oCol?.formatExcel ? oCol.formatExcel(originalValue) 
                                                : (['string','number'].includes( typeof originalValue )) ? originalValue : ''
                        // ,formatValue    = oCol.format ? oCol.format( valueToDisplay, oRow ) : valueToDisplay
                        ;
                        return (
                            ( typeof valueToDisplay === 'string' )
                               ? ( '"' + valueToDisplay  + '"' )
                               : utils.fixDecimals(valueToDisplay, 2)
                        );
                    })
            )
        ];
        utils.fileSaver( aasDataRows, sFileName + ` ( ${ moment().format('YYYY-MM-DD HH_mm_ss') } )` );
        setTimeout( set_isDisabledExport, 2000, false);
    };

    // useEffect(() => {
    //     console.error('Simple Table')
    //     // sortTable(( aoCols.filter( oCol => oCol.isUniqueKeyForRow ) ));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        if ( !noHeight ) {
            set_height(paperContainer?.current?.clientHeight + offSetHeight);
        }
        set_headGroupRowHeight( headGroupRow?.current?.clientHeight );
        set_headersRowHeight(   headersRow?.current?.clientHeight );
        // if ( !aoRows.length ) { console.error('Nessun dato da visualizzare') }
        
        // questa riga non va bene perché può esserci più di una colonna isUniqueKeyForRow
        // e sortTable prevede una sola colonna come parametro
        // sortTable(aoCols.find( oCol => oCol.isUniqueKeyForRow ) );
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        set_aoFilteredRows( bOnlySelected ? [...aoRows].filter( isCheckedFunction ) : [...aoRows] );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bOnlySelected]);
    
    // Funzione che riceve un numero e la stringa di filtro, es. ">12" o "=100" o "<5".
    // Restituisce true se la riga dev’essere tenuta, false se va scartata.
    function applyNumberFilter( sRowValue, sValueFiltered ) {
        
        // se il valore sulla riga non è un numero, lo escludo
        const nRowValue = +sRowValue;
        if ( Number.isNaN(nRowValue) ) {
            return false;
        }
        
        sValueFiltered  = sValueFiltered || '=';
        // Se sValueFiltered è esattamente "=", "≠", ">", "<" oppure è vuoto/falsy allora significa "niente filtro", quindi passa tutto
        if ( ( sValueFiltered?.length === 1 ) && ['=','≠','>','<'].includes( sValueFiltered ) ) {
            return true;
        }
        
        // estraiamo l’operatore (primo carattere)
        let sOperator   = sValueFiltered[0];
        // e la parte numerica (resto della stringa)
        let sNumberPart = sValueFiltered.slice(1);
        
        // se il valore filtrato non è numero, escludo tutte le righe
        const nNumber   = +sNumberPart;
        if ( Number.isNaN(nNumber) ) {
            return false;
        }
        
        if (        sOperator === '=' ) {
            return nRowValue === nNumber;
        } else if ( sOperator === '≠' ) {
            return nRowValue !== nNumber;
        } else if ( sOperator === '>' ) {
            return nRowValue  >  nNumber;
        } else if ( sOperator === '<' ) {
            return nRowValue  <  nNumber;
        } else {
            return false; // Operatore non riconosciuto, escludo tutte le righe
        }
        
    }
    
    function applyTextFilter( sRowValue, sValueFiltered ) {
        
        // sValueFiltered ad es. "cwsCiao", "CWsHELLO", ecc.
        // 1° char:  c/C -> case insensitive / sensitive
        // 2° char:  w/W -> parola esatta / ricerca all'interno
        // 3° char:  s/S -> ignora speciali / includi speciali
        // dal 4° char in poi: testo di ricerca
        
        // Per sicurezza, se sValueFiltered è troppo corto, usiamo default "cws"
        const sFlagCase  = ( sValueFiltered[0] || 'c' ); // c -> insensitive, C -> sensitive
        const sFlagWord  = ( sValueFiltered[1] || 'w' ); // w -> esatta, W -> interna
        const sFlagSpec  = ( sValueFiltered[2] || 's' ); // s -> rimuovi speciali, S -> lascia speciali
        let sText        = sValueFiltered.slice(3);      // testo vero e proprio
        
        // Se i caratteri speciali NON vanno inclusi, li rimuoviamo sia da sRowValue che da sText
        if ( sFlagSpec === 's' ) {
            sRowValue = removeSpecialChars(sRowValue);
            sText     = removeSpecialChars(sText);
        }
        
        // Se case insensitive, trasformiamo tutto in minuscolo
        if ( sFlagCase === 'c' ) {
            sRowValue = sRowValue.toLowerCase();
            sText     = sText.toLowerCase();
        }
        
        // Se la ricerca è "w" minuscolo, allora cerchiamo "all'interno" (partial match)
        // Se "W" maiuscolo, allora richiediamo match ESATTO
        if ( sFlagWord === 'w' ) {
            return sRowValue.includes(sText);
        } else {
            return sRowValue === sText;
        }
        
    }
    
    
    const aoRowsUpdatedWithAllFilters = () => {
        
        let aoRowsUpdated = [...aoRows];
        
        // applico ogni filtro inserito (compreso l'ultimo appena aggiunto)
        const asFilterKeys = Object.keys( oColumnFilters );
        for ( let nFilterKey = 0; nFilterKey < asFilterKeys.length; nFilterKey++ ) { // per ogni valore filtrato
            
            const sColumnFilterName = asFilterKeys[nFilterKey];
            
            let sValueFiltered = '', oValueFiltered;
            const vValueFiltered        = oColumnFilters[sColumnFilterName];
            const isValuefilteredObject = ( typeof(vValueFiltered) === 'object' );
            
            if ( isValuefilteredObject ) {
                oValueFiltered = vValueFiltered;
            } else {
                sValueFiltered = vValueFiltered;
            }
            
            if ( isValuefilteredObject ) {
                
                const asColKeys = Object.keys( oValueFiltered );
                for ( let nColKey = 0; nColKey < asColKeys.length; nColKey++ ) {
                    
                    const sColKey           = asColKeys[nColKey];
                    
                    sValueFiltered          = oValueFiltered[ sColKey ];
                    
                    const oColFound         = ( aoCols.find( oCol => !oCol.noFilter && ( oCol.name === sColumnFilterName ) && ( ( oCol.key + '' ) === ( sColKey + '' ) ) ) || {} );
                    const selectOptions     = oColFound.selectOptions;
                    const formatFunction    = ( oColFound.format && !oColFound.filterOriginalValue ) ? oColFound.format : ( val => val );
                    
                    aoRowsUpdated           = aoRowsUpdated.filter( oRow => {
                        
                        if  ( !!selectOptions ) {
                            const oActualOption = selectOptions.find( oSelectOption => ( oSelectOption.value + '' ) === ( sValueFiltered + '' ) );
                            return oActualOption?.checkFunc( oRow[sColumnFilterName] || '', isValuefilteredObject ? sColKey : sValueFiltered );
                            
                        } else {
                            
                            if (        oColFound?.type === 'Number' ) {
                                return applyNumberFilter( oRow[sColumnFilterName], sValueFiltered );
                            } else if ( oColFound?.type === 'Date'   ) {
                                return removeSpecialChars( formatFunction(oRow[sColumnFilterName], oRow ) ).includes( removeSpecialChars(sValueFiltered) );
                            } else {
                                return applyTextFilter( formatFunction( oRow[sColumnFilterName], oRow ) + '', sValueFiltered);
                            }
                            
                        }
                        
                    });
                    
                }
                
            } else {
                
                const oColFound         = ( aoCols.find( oCol => !oCol.noFilter && ( oCol.name === sColumnFilterName ) ) || {} );
                const selectOptions     = oColFound.selectOptions;
                const formatFunction    = ( oColFound.format && !oColFound.filterOriginalValue ) ? oColFound.format : ( val => val );
                
                aoRowsUpdated = aoRowsUpdated.filter( oRow => {
                    
                    if  ( !!selectOptions ) {
                        const oActualOption = selectOptions.find( oSelectOption => ( oSelectOption.value + '' ) === ( sValueFiltered + '' ) );
                        return oActualOption?.checkFunc( oRow[sColumnFilterName] || '', sValueFiltered );
                        
                    } else {
                        
                        if ( oColFound?.type === 'Number' ) {
                            return applyNumberFilter( oRow[sColumnFilterName], sValueFiltered );
                        } else if ( oColFound?.type === 'Date'   ) {
                            return removeSpecialChars( formatFunction(oRow[sColumnFilterName], oRow ) ).includes( removeSpecialChars(sValueFiltered) );
                        } else {
                            return applyTextFilter( formatFunction( oRow[sColumnFilterName], oRow ) + '', sValueFiltered);
                        }
                    }
                    
                });
                
            }
            
        }

        return aoRowsUpdated;

    };

    const handleFilterValues = ({ sValueToFilter, sColumnName, key }) => {
        
        // aggiungo l'attuale valore filtrato alla colonna interessata
        if ( key ) {
            if ( !oColumnFilters[ sColumnName ] ) {
                oColumnFilters[ sColumnName ] = {};
            }
            oColumnFilters[ sColumnName ][ key ] = sValueToFilter;
        } else {
            oColumnFilters[ sColumnName ] = sValueToFilter;
        }
        
        const aoRowsUpdated = aoRowsUpdatedWithAllFilters();
        
        // aggiorno righe e colonne
        set_oColumnFilters( oColumnFilters );
        set_aoFilteredRows( aoRowsUpdated );

    }

    const onChangeSelectAll         = (event) => {
        set_bSelectAll(!!event.target.checked);
    }

    useEffect( ()=>{
        // ATTENZIONE: importante che bSelectAll sia inizializzato a null e poi nella useEffect si verifichi che sia boolean
        if ( oCheckOptions.isVisible && ( typeof bSelectAll === 'boolean' ) ) {
            fCheckAll(bSelectAll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bSelectAll]);

    useEffect( ()=>{
        if ( aoRows.every( oRow => oRow.FLAG_SELECTED === 'Y' ) ) {
            set_bSelectAll(true);
        }
        set_aoFilteredRows(aoRowsUpdatedWithAllFilters() );
        // fix per aggiornare lo stato della tabella quando aoRows (una prop) viene aggiornata dal padre
        // ad ogni modifica di aoRows risetto lo stato associato "aoFilteredRows" tenendo conto dei filtri già applicati
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ aoRows ]);

    const isHeadersGrouped = aoCols.some( oCol => oCol.group );
    
    const aoColsGroups     = aoCols.reduce( ( aoColsGroups, oCol ) => {
        let oGroupToFind = aoColsGroups.find( o => o.group === oCol.group );
        if ( !oCol.group ) { // se non fa parte di un gruppo creo un oggetto con nColSpan = 1
            aoColsGroups.push({ ...oCol, nColSpan: 1 });
        } else if ( oCol.group && !(oGroupToFind) ) { // se non esiste ancora l'oggetto gruppo, lo crea e gli dà valore 1
            aoColsGroups.push({ ...oCol, nColSpan: 1 }); 
        } else { // altrimenti se esiste già aumenta il suo valore di 1
            oGroupToFind.nColSpan += 1; 
        }
        return aoColsGroups
    } ,[]);


    /*
    const handleCheck = ( { isChecked, oRow } ) => {
        handleChangeCheck({ isChecked, oRow });
        setAoRows( [...aoRows] );
    };

    const  handleHighlight = ( { isHighlighted, oRow } ) => {
        aoRows.map( row => handleChangeHighlight({ isHighlighted: false, oRow: row }) ); // toglie evidenziazione da tutte le righe
        handleChangeHighlight({ isHighlighted: !isHighlighted, oRow }); // evidenzia solo la riga interessata
        setAoRows( [...aoRows] );
    };
    */
    
    const fMapSameValues = () => {
        
        let oPrevRow        = {};
        let aMappedSameRow  = [];
        const aoSortCols    = ( aoCols.filter( oCol => oCol.sort ) || {} );
        
        const aoSortedRows  = fGetRecord ? [ ...aoFilteredRows ] : sortBy(
             aoFilteredRows 
            ,aoSortCols.map( oSortcol => oSortcol.name ) 
            ,aoSortCols.map( oSortcol => oSortcol.sort )
        );
        
        for ( let nRow = 0; nRow < aoSortedRows.length; nRow++ ) {
            const oRow = aoSortedRows[nRow];
            const isChecked = isCheckedFunction( oRow );
            aMappedSameRow.push( 
                <tr
                    key       ={ 'tr' + getUniqueKey(    oRow, asKeyColumnNames ) }
                    className ={ ( isHighlightable && isHighlightedFunction( oRow ) ? ' highlight ' : '' )
                            + ( oRowOptions && oRowOptions.addClass ? oRowOptions.addClass(oRow) : '' ) }
                    onClick   ={ () => { isHighlightable && fHighlightOnlyOne( oRow ) } }
                >
                    {   // colonna per CHECKBOX
                        oCheckOptions.isVisible &&
                        <td key="checkcolumn" className="check">
                            <Checkbox // disabilita checkbox se si supera il massimo di elementi selezionabili
                                disabled ={ !oCheckOptions.isEnabled && !isChecked && bFiltersLimitReached }
                                checked  ={ isCheckedFunction(oRow) || isChecked }
                                onClick  ={ (event) => { event.stopPropagation() } }
                                onChange ={ (event) => fCheckOne( { isChecked: event.target.checked, oRow } ) }
                                color    ="primary"
                            />
                        </td>
                    }
                    {   // colonna per EDIT
                        isEditable && <td className="edit" onClick={ () => { editFunction(oRow) } } >
                            <Tooltip title="Edit" placement='right' arrow>
                                <EditIcon />
                            </Tooltip>
                        </td>
                    }
                    {   // corpo della tabella (i dati)

                        // eslint-disable-next-line no-loop-func
                        aoCols.map( (oCol, nCol) => {
                            const
                                 originalValue  = !isDemo ? oRow[ oCol.name ] : ( 
                                     oCol.isNum ? ~~( ( +oRow[ oCol.name ] + numeroCasualeDecimale(+oRow[ oCol.name ]) ) * numeroCasualeDecimale(+oRow[ oCol.name ]) ) 
                                                : ( randomizeOnlyNumbersForDemo ? oRow[ oCol.name ] : ( oCol.name + nRow ) )
                                 )
                                ,valueToDisplay = ['string','number'].includes( typeof originalValue ) ? originalValue : ''
                                ,formatValue    = ( typeof oCol?.format === 'function' ) ? oCol?.format( valueToDisplay, oRow ) : null
                                ,{ output, fromNow, ...originalDateOptions } = oCol?.dateOptions || {}
                                ,formatOrOriginal = (
                                    ( oCol.format          ) ? formatValue :
                                    ( oCol.type === 'Date' ) ? utils.formatDateTime( valueToDisplay, {
                                        ...originalDateOptions, ...( showDateRelative && { fromNow: true, output: '' }) , ...( !showDateRelative && { output })
                                    }) :
                                    valueToDisplay
                                )
                                ,optionalProps  = !oCol.onHover ? {} : {
                                    onMouseEnter:() => { timeoutIdOnHover = setTimeout( () => { set_coordHover([nRow, nCol]) }, 250 ) },
                                    onMouseLeave:() => { clearTimeout(timeoutIdOnHover);  set_coordHover([]) }
                                }
                                ,cellContent    = ( oCol.onHover && ( coordHover[0] === nRow ) && ( coordHover[1] === nCol ) )
                                                    ? oCol.onHover( formatOrOriginal, oRow.KUSER, oCol.key )
                                                    : exception( formatOrOriginal, oRow, oCol, oRow.asCurrentFilters )
                                ,isSame         = oCol.showDiff && ( originalValue === oPrevRow[ oCol.name ] ) ? ' isSame ' : ''
                                ,uniqueClass    = oCol.isUniqueKeyForRow ? ' unique ' : ''
                                ,numberClass    = oCol.isNum             ? ' number ' : ''
                            ;

                            return <td
                                key       = { 'td' + oCol.name + getValidTitle(oCol) + nCol  }
                                className = {
                                      oCol.name
                                    + uniqueClass
                                    + numberClass
                                    + ' ' + (   oCol.group               ? ( 'ingroup ' + oCol.group )  : '' )
                                    + ' ' + ( ( oCol.group && oCol.key && oCol.format && ['string','number'].includes( typeof formatValue ) ) ? formatValue : '' )
                                    + ' ' + ( ( oCol.additionalClass || ((v)=>'') )(originalValue) )
                                    + isSame
                                }
                                style     = { { minWidth: oCol.width || '', width: oCol.width || '', maxWidth: oCol.width || '' } }
                                title     = { !oCol.tooltip
                                                    ? ''
                                                    : ['string','number'].includes( typeof formatValue ) 
                                                        ? formatValue 
                                                        : valueToDisplay }
                                { ...optionalProps }
                            >{
                                cellContent
                            }</td>
                        })

                    }
                </tr>
            )
            
            oPrevRow = oRow;
            
        }

        return aMappedSameRow;
    };
    
    const sortTable = (oCol) => {

        // se non è stata abilitata la possibilità di selezionare più di una colonna per l'ordinamento
        // allora, appena si ordina per una colonna, viene eliminato l'ordinamento per tutte le altre
        if ( !oSortOptions.isMultiColSortable ) {
            for ( let i = 0; i < aoCols.length; i++ ) {
                if ( aoCols[i].name !== oCol.name ) {
                    delete aoCols[i].sort;
                }
            }
        }
        
        if ( oSortOptions.isSortable ) {
            if ( fGetRecord ) { 
                oCol.sort = ( oSortOptions?.oSort?.sort !== 'DESC' ) || ( oSortOptions?.oSort?.name !== oCol.name ) ? 'DESC' : 'ASC';
                (async () => {
                    await fGetRecord({ oCol: { ...oCol } });
                    // avendo aggiornato una colonna, è sufficiente riscatenare l'aggiornamento di uno stato
                    set_aoFilteredRows([...aoFilteredRows]);
                })();
            } else {
                oCol.sort = ( oCol.sort !== 'DESC' ) ? 'DESC' : 'ASC';
                // avendo aggiornato una colonna, è sufficiente riscatenare l'aggiornamento di uno stato
                set_aoFilteredRows([ ...sortBy( aoFilteredRows, [oCol.name], [oCol.sort] ) ]);
            }
        }
        
    }
    
    if ( !Object.keys(aoRows).length || !Object.keys(aoCols).length ) {
        return <></>;
    }

    const tableTool = <div className='headerTableTool-outer'>
        <div className='headerTableTool-inner'>
            { isExportable && <Tooltip title='Download CSV' placement='right' arrow>
                <Button 
                    className ='side-simpletable-menu-opener myShadow'
                    onClick   ={ exportCSV }
                    disabled  ={ isDisabledExport }
                >
                    <DownLoadIcon className={'bigIcon textGrey'} />
                </Button>
            </Tooltip> }
            { aoCols.find( o => o.type === 'Date' ) && // solo se c'è almeno un campo con type = 'Date'
                <Select
                    variant     = 'standard'
                    onChange    ={ (event) => { set_showDateRelative( event.target.value === 'relative') } }
                    value       ={ showDateRelative ? 'relative' : 'absolute' }
                    className   ="date-options"
                >
                    <MenuItem value="relative">Date Relative</MenuItem>
                    <MenuItem value="absolute">Date Assolute</MenuItem>
                </Select>
            }
            { oPaginationOption.isPagination && ( oPaginationOption.oPag.nTotRecord > oPaginationOption.oPag.pNumRecords ) &&
                <PaginationTool oPaginationOption = {{ fGetRecord, oPag: oPaginationOption.oPag }}/> }
        </div>
    </div>;

    
    return <Paper
        key       ={chiave}
        className ={
            'SimpleTable-wrapper '
            + ( isEmpty ? ' no-data ' : ' ' )
            + ( extraClasses || ' ' )
            + ' ' + sTableDataType
            + ' ' + className
        }
        ref       ={ paperContainer }
        style     ={ height ? { height } : {} }
    >
        { tableTool }
    {/* <Tooltip title='Menu' placement='top' arrow><div className="dianButton side-simpletable-menu-opener link myShadow" onClick={ openMenuTable }><ThreeVerticalDot /></div></Tooltip> !!! TODO
        <Tooltip title='Download' placement='bottom' arrow><div className={`side-simpletable-menu link myShadow ${ isMenuOpen ? '' : 'hiddenElement' }`} onClick={ exportCSV }><DownLoadIcon /></div></Tooltip> */}
        <div className={ 'SimpleTable ' + chiave } key={chiave}>
            <table>
                <thead>
                {   // INTESTAZIONI dei GRUPPI di colonne
                    ( noHeader || !isHeadersGrouped ) ? null :
                    <tr className='cols-groups'>
                        { oCheckOptions.isVisible && <th key="checkcolumn" className="check" ><div></div></th> }
                        { isEditable              && <th key="editcolumn"  className="edit"  ><div></div></th> }
                        { aoColsGroups.map( ( oColGroup, nColGroup ) => {
                            // const nColSpan = aoColsGroups[oColGroup].nColSpan;
                            const hasColSpan = oColGroup.nColSpan > 1;
                            return <th
                                ref       ={ ( nColGroup === 0 ) ? headGroupRow : null }
                                key       ={ 'group' + oColGroup.group + oColGroup.name + nColGroup }
                                className ={
                                    (
                                        hasColSpan
                                            ? 'colgroup '
                                            : ( 'nocolgroup ' + ( ( oColGroup.additionalClass || ((v)=>'') )() || '' ) )
                                    )
                                    + ' ' + ( oColGroup.group || '' )
                                    + ' ' + oColGroup.name
                                }
                                { ...( hasColSpan && { colSpan: oColGroup.nColSpan }) }
                                { ...( hasColSpan && { title:  ( oHeadersOptions.tooltip && oColGroup.group ) ? oColGroup.group : '' }) }
                            >{ hasColSpan ? oColGroup.group : '' }</th>
                        }) }
                    </tr>
                }
                {   // INTESTAZIONI delle COLONNE
                    noHeader ? null :
                    <tr>
                        { oCheckOptions.isVisible && <th key="checkcolumn" className="check" style={ { top: headGroupRowHeight || 0 } } ><div></div></th> }
                        { isEditable              && <th key="editcolumn"  className="edit"  style={ { top: headGroupRowHeight || 0 } } ><div></div></th> }
                        { aoCols.map( ( oCol, nCol ) => {
                            const nColWidth = ( oCol.width + +( oSortOptions.isSortable && !oCol.notSortable && 14 ) ) || '';
                            return <th
                                ref       ={ nCol === 0 ? headersRow : null }
                                key       ={ 'headers' + oCol.name + oCol.key + nCol }
                                onClick   ={ ( typeof oCol?.onClickColHead === 'function' ) ? oCol.onClickColHead : (() => {}) }
                                className ={
                                     oCol.name
                                     + ' ' + ( oCol.group                ? ( 'ingroup ' + oCol.group )  : '' )
                                     + ' ' + ( oCol.isUniqueKeyForRow    ? 'unique'                     : '' )
                                     + ' ' + ( oCol.isNum                ? 'number'                     : '' )
                                     + ' ' + (  ( oCol.additionalClass || ((v)=>'') )() )
                                     + ' ' + ( oHeadersOptions.breakline ? 'breakline'                  : '' )
                                }
                                style     ={ { minWidth: nColWidth, width: nColWidth, maxWidth: nColWidth, top: headGroupRowHeight || 0 } } 
                                title     ={ ( oHeadersOptions.tooltip && ( oCol.headerTooltip || getValidTitle(oCol) ) ) || '' }
                            >{
                                oSortOptions.isSortable && !oCol.notSortable && aoRows.length > 1 &&
                                <div
                                    className={
                                        'arrows-icon updown ' + (
                                            ( 
                                                ( !fGetRecord && oCol.sort ) || 
                                                ( ( oSortOptions?.oSort?.name === oCol.name ) && oSortOptions?.oSort?.sort )
                                            ) || '' 
                                        )
                                    }
                                     onClick={ () => { sortTable(oCol); } }></div>
                            }{ oCol.title }</th>
                        } ) }
                    </tr>
                }
                {   // riga dei FILTRI
                    (noFilter || (aoRows.length <= 1)) ? null :
                    <tr className="filter">
                        { oCheckOptions.isVisible &&
                            <th key="checkcolumn" className="check" style={ { top: ( headGroupRowHeight || 0 ) + ( headersRowHeight || 0 )} }>
                                <Tooltip title={ bSelectAll ? 'Deselect all' : 'Select all' } placement='top' arrow>
                                    <Checkbox className="check-sel-all" checked={ !!bSelectAll } onChange={ onChangeSelectAll } />
                                </Tooltip>
                            </th>
                        }
                        { isEditable && <th key="editcolumn" className="edit" style={ { top: ( headGroupRowHeight || 0 ) + ( headersRowHeight || 0 )} }/> }
                        { // filtri sulle righe
                            aoCols.map( ( oCol, nCol ) => {
                                
                                let sValueToFilter = oColumnFilters[ oCol.name ];
                                sValueToFilter     = ( sValueToFilter === 0 ) ? 0 : ( sValueToFilter || '' );
                                if ( ![ 'string', 'number' ].includes( typeof sValueToFilter ) && sValueToFilter ) {
                                    sValueToFilter = sValueToFilter[ oCol.key ];
                                }
                                
                                // "Decodifica" il valore corrente (sValueToFilter) in 3 flag + testo.
                                // Se manca, partiamo da "cws" (c-w-s) come default.
                                const
                                     sFilter   = ( ( sValueToFilter || '' ) + '' ) || 'cws' // es: "CWsTesto"
                                    ,sFlagCase = sFilter?.[0]   || 'c'   // primo carattere (c/C)
                                    ,sFlagWord = sFilter?.[1]   || 'w'   // secondo carattere (w/W)
                                    ,sFlagSpec = sFilter?.[2]   || 's'   // terzo carattere (s/S)
                                    ,sTextPart = sFilter?.slice(3)       // dal quarto carattere in poi
                                ;
                                
                                // Pulsante 1: Case
                                const handleToggleCase      = () => {
                                    const sNewCase = (sFlagCase === "C") ? "c" : "C";
                                    handleFilterValues({
                                        sValueToFilter: sNewCase + sFlagWord + sFlagSpec + sTextPart,
                                        sColumnName: oCol.name
                                    });
                                };
                                
                                // Pulsante 2: Word
                                const handleToggleWord      = () => {
                                    const sNewWord = (sFlagWord === "W") ? "w" : "W";
                                    handleFilterValues({
                                        sValueToFilter: sFlagCase + sNewWord + sFlagSpec + sTextPart,
                                        sColumnName: oCol.name
                                    });
                                };
                                
                                // Pulsante 3: Special
                                const handleToggleSpecial   = () => {
                                    const sNewSpec = (sFlagSpec === "S") ? "s" : "S";
                                    handleFilterValues({
                                        sValueToFilter: sFlagCase + sFlagWord + sNewSpec + sTextPart,
                                        sColumnName: oCol.name
                                    });
                                };
                                
                                // Pulsante 4: Clear (svuota SOLO la parte di testo, lasciando i 3 flag invariati)
                                const handleClear           = () => {
                                    handleFilterValues({
                                        sValueToFilter: sFlagCase + sFlagWord + sFlagSpec, // niente testo
                                        sColumnName: oCol.name
                                    });
                                };
                                
                                const handleClearDate       = () => handleFilterValues({
                                     sValueToFilter:   ''
                                    ,sColumnName:      oCol.name
                                })
                                
                                const specificProps         = {
                                     value:     sTextPart
                                    ,onChange:  (event) => {
                                        handleFilterValues({
                                             sValueToFilter:    sFlagCase + sFlagWord + sFlagSpec + event.target.value
                                            ,sColumnName:       oCol.name
                                        })
                                    }
                                }
                                
                                const renderTextField       = ( isAutocomplete = false, params = {} ) => (
                                    <TextField
                                        {...params} // diffonde ref, inputProps, etc.
                                        { ...( !isAutocomplete ? specificProps : {} ) }
                                        type        ="text"
                                        variant     ="standard"
                                        placeholder =""
                                        title       ="Filtro"
                                        fullWidth
                                        inputProps  ={ { ...params.inputProps, spellCheck: "false" } }
                                        InputProps  ={ { ...params.InputProps,
                                            ...( oCol.advancedTextFilters && {
                                            endAdornment: <InputAdornment position="end" style={{ marginLeft: 0 }}>
                                                
                                                {/* 1) Tasto Case sensitive/insensitive */}
                                                <IconButton
                                                    onClick   ={handleToggleCase}
                                                    color     ={ sFlagCase === 'C' ? 'primary' : 'default' }
                                                    className ="text-filter-btn"
                                                    title     ={ sFlagCase === 'C' ? 'Non ignorare MAIUSCOLE e minuscole' : 'Ignora maiuscole e minuscole' }
                                                >
                                                    Cc
                                                </IconButton>
                                                
                                                {/* 2) Tasto Ricerca Esatta o Interna al testo */}
                                                <IconButton
                                                    onClick   ={handleToggleWord}
                                                    color     ={ sFlagWord === 'W' ? 'primary' : 'default' }
                                                    className ="text-filter-btn"
                                                    title     ={ sFlagCase === 'W' ? 'Corrispondenza esatta dell\'intera parola' : 'Ricerca all\'interno del testo' }
                                                >
                                                    W
                                                </IconButton>
                                                
                                                {/* 3) Tasto Includi/Escludi Caratteri Speciali */}
                                                <IconButton
                                                    onClick   ={handleToggleSpecial}
                                                    color     ={ sFlagSpec === 'S' ? 'primary' : 'default' }
                                                    className ="text-filter-btn"
                                                    title     ={ sFlagCase === 'S' ? 'Includi caratteri speciali (!$%&?^@#...)' : 'Ignora caratteri speciali (!$%&?^@#...)' }
                                                >
                                                    *?!
                                                </IconButton>
                                                
                                                {/* 4) Tasto Clear (svuota il campo) */}
                                                <IconButton
                                                    onClick   ={handleClear}
                                                    className ="text-filter-btn"
                                                    title     ="Svuota il filtro"
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            
                                            </InputAdornment>
                                            })
                                        }}
                                    />
                                );
                                
                                const renderAutocomplete    = () => (
                                    <Autocomplete
                                        freeSolo
                                        openOnFocus
                                        options         ={ [...new Set(aoFilteredRows.map( o => ( o[oCol.name] + '' ).toUpperCase() ) ) ].sort() }
                                        getOptionLabel  ={ (sOption) => sOption }
                                        inputValue      ={ sTextPart }
                                        onInputChange   ={ (event, newInputValue) => {
                                            // Usa onInputChange per aggiornare il testo del filtro
                                            handleFilterValues({
                                                 sValueToFilter: sFlagCase + sFlagWord + sFlagSpec + newInputValue
                                                ,sColumnName:    oCol.name
                                            });
                                        }}
                                        renderInput     ={ (params) => renderTextField( true, params ) }
                                    />
                                );
                                
                                return <th
                                    style     ={ { top: ( headGroupRowHeight || 0 ) + ( headersRowHeight || 0 )} }
                                    key       ={ 'filters' + oCol.name + nCol }
                                    className ={
                                        oCol.name
                                        + ' ' + ( oCol.group                ? ( 'ingroup ' + oCol.group )  : '' )
                                        + ' ' + ( oCol.isUniqueKeyForRow    ? 'unique'                     : '' )
                                        + ' ' + ( oCol.isNum                ? 'number'                     : '' )
                                        + ' ' + ( oCol.isAutocomplete       ? 'autocomplete'               : '' )
                                        + ' ' + ( ( oCol.additionalClass || ((v)=>'') )() )
                                    }
                                > {
                                    oCol.noFilter ? '' :
                                        
                                        // filtro in versione select con opzioni
                                    ( !!oCol.selectOptions ) ? 
                                        <Select
                                            value       ={ sValueToFilter || '' }
                                            variant     = 'standard'
                                            onChange    ={ (event) => {
                                                handleFilterValues({
                                                     sValueToFilter:     event.target.value
                                                    ,sColumnName:        oCol.name
                                                 // ,formatFunction:     oCol.format         // NON VIENE USATO
                                                 // ,selectOptions:      oCol.selectOptions  // NON VIENE USATO
                                                    ,key:                oCol.key
                                                })
                                            }}
                                            style={{ minWidth: 48 }}
                                        >{
                                            ( oCol?.selectOptions || [] ).map( (oSelectItem, index) =>
                                                <MenuItem
                                                    key   ={ 'trioptions' + oSelectItem.value + index } 
                                                    value ={ oSelectItem.value }
                                                >{ oSelectItem.label }</MenuItem>
                                            )
                                        }</Select>
                                    
                                    : ( oCol.type === 'Date' ) ? (
                                        <LocalizationProvider dateAdapter={AdapterMoment} localeText={customLocale.localeText}>
                                            <DatePicker
                                                id          = "simple-table-data-inizio"
                                                className   = "simple-date"
                                             // onKeyDown   = { (event) => clearOnCANCorBACKSPACE(event, handleClearDate) }
                                                format      = { 'DD/MM/YYYY' }
                                                views       = {['year', 'month', 'day']}
                                                slotProps   = {{
                                                    textField: {
                                                         variant: 'standard'
                                                        ,InputProps: {
                                                            startAdornment: oCol?.dateOptions?.showClear && sValueToFilter && (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label ="clear date"
                                                                        onClick    ={ handleClearDate }
                                                                        edge       ="start"
                                                                        size       ="small"
                                                                    >
                                                                        <ClearIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }
                                                    }
                                                    ,actionBar: { actions: ['clear', 'cancel'] }
                                                }}
                                                value       = { sValueToFilter ? moment(sValueToFilter,  oCol?.dateOptions?.input ) : null }
                                                onChange    = { (momentDate) => {
                                                    handleFilterValues({
                                                                            // in questo punto filtro solamente la giornata e NON l'orario
                                                         sValueToFilter:    momentDate ? momentDate.format( 'YYYYMMDD' ) : ''
                                                        ,sColumnName:       oCol.name
                                                    })
                                                }}
                                            />
                                        </LocalizationProvider>
                                    )
                                                               
                                    : ( oCol.type === 'Number' ) ? (
                                         <TextField
                                             /* sarebbe più corretto type="number" ma voglio concatenare i simboli > = < assieme al numero stesso */
                                             type        ="text"
                                             variant     ="standard"
                                             value       ={ ( sValueToFilter || '' ).slice(1) || '' }
                                             className   ="simple-number"
                                             onKeyDown   ={ isIntKey }
                                             onFocus     ={ autoSelectOnFocus }
                                             onChange    ={ (event) => {
                                                 handleFilterValues({
                                                      sValueToFilter:    ( ( sValueToFilter || '' )[0] || '=' ) + event.target.value
                                                     ,sColumnName:       oCol.name
                                                  // ,formatFunction:    oCol.format // NON VIENE USATO
                                                 })
                                             }}
                                             placeholder =""
                                             title       ="Filtro"
                                             fullWidth
                                             InputProps  ={{
                                                  inputProps:       { maxLength: 4 }
                                                 ,spellCheck:       "false"
                                                 ,startAdornment:   (
                                                     <InputAdornment position="start">
                                                         <select
                                                             value      ={ ( sValueToFilter || '' )[0] || '=' }
                                                             className  ="select-operator-number"
                                                             onChange   ={ (event) => {
                                                                 handleFilterValues({
                                                                      sValueToFilter:    event.target.value + ( sValueToFilter || '' ).slice(1)
                                                                     ,sColumnName:       oCol.name
                                                                 })
                                                             }}
                                                         >
                                                             <option value="=">=</option>
                                                             <option value="≠">≠</option>
                                                             <option value=">">&gt;</option>
                                                             <option value="<">&lt;</option>
                                                         </select>
                                                     </InputAdornment>
                                                 )
                                             }}
                                         />
                                    )
                                    
                                    // filtro versione con input libero da parte dell'utente digitando il testo
                                    : oCol?.isAutocomplete ? renderAutocomplete() : renderTextField()
                                    
                                } </th>
                            })
                        }
                    </tr>
                }
                </thead>
                <tbody>
                {   // CORPO della tabella
                    fMapSameValues()
                }
                </tbody>
                {/* {
                    oPaginationOption.isPagination && ( oPaginationOption.oPag.nTotRecord > oPaginationOption.oPag.pNumRecords ) && 
                    <tfoot><tr><th className='myFoot' colSpan={100}>
                        { tableTool }
                    </th></tr></tfoot>
                } */}
            </table>
        </div>
        { oPaginationOption.isPagination && ( oPaginationOption.oPag.nTotRecord > oPaginationOption.oPag.pNumRecords ) && tableTool } 
        { /* Ripetizione di condizione per i casi di tabella con più di una pagina */}
    </Paper>

}
