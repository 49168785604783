import React, { useState, useEffect, useContext, createContext } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Button                       from '@mui/material/Button';
import IconButton                   from '@mui/material/IconButton';
import axios                        from 'axios';
import moment                       from 'moment';
import 'moment/locale/it';

import './Anagrafiche.css';
import config                       from '../../config.js';
import { AuthContext }              from '../../contexts/authContext';
import { AppContext }               from '../../App.js';
import { SimpleTable }              from '../../components/SimpleTable/SimpleTable';
import AnagrafichePercentuale       from './Component/AnagrafichePercentuale.js';
import AnagrafichePopup             from './Component/AnagrafichePopup.js';
import CircleProgress               from '../../components/CircleProgress/CircleProgress.tsx';
import utils                        from '../../util/CommonUtilities';
import { CachedIcon
        ,CheckIcon 
        ,DoubleArrowLeftIcon
        ,DoubleArrowRightIcon
        ,FolderOffIcon }            from '../../assets/icons/icons.js';
import { Checkbox
        ,Tooltip      }             from '@mui/material';
import { CustomNoWidthTooltip }     from '../../components/CustomCSS/CustomCSS.tsx';
    
const bDebug = config.DEBUG && false;
const nNumRecords = config.NUM_RECORDPAGE || 500;
const asTypeInPerc = [ 'HOUSE', 'SPONSORSHIP', 'NETWORK' ];
const aoAlarmsHeader = { 1:  'LineItem senza materiali associati'
                        ,2:  'LineItem non partiti'
                        ,3:  'LineItem in delivery con click a zero'
                        ,4:  'LineItem in delivery senza Placement e AdUnit'
                        ,5:  'LineItem in underdelivery'
                        ,6:  'LineItem in pausa'
                        ,10: 'LineItem con viewability sotto soglia'
                        ,11: 'LineItem con Errori Vast sopra soglia'
                    }

const AnagraficheDettaglio = () => {
    
    const kUser = localStorage.getItem('kUser');
    const auth = useContext(AuthContext);
    const headers = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    };
    const isDemo    = process.env.REACT_APP_ENV.includes('demo')
    const isSole    = process.env.REACT_APP_ENV.includes('sole');
    const urlParams = new URLSearchParams(window.location.search);
    const origin    = urlParams.get('origin') || '';
    let sContendingName         = localStorage.getItem('sContendingName')         || '';
    let sLineItemContainerName  = localStorage.getItem('sLineItemContainerName')  || '';
    let sPlacementContainerName = localStorage.getItem('sPlacementContainerName') || '';
    let sPlacementChildName     = localStorage.getItem('sPlacementChildName')     || '';
    let sAdUnitChildName        = localStorage.getItem('sAdUnitChildName')        || '';
    const { account, accountcode, dettaglio, idDettaglio } = useParams();
    const { set_oNotifyOptions, set_dLastUpdate, oFilterParamsTotal } = useContext(AppContext);
    let   { [dettaglio]: oFilterParams } = oFilterParamsTotal;

    bDebug && console.log('oFilterParams: ', oFilterParams);
    bDebug && console.log('origin: ', origin);
    bDebug && console.log('idDettaglio: ', idDettaglio);

    // Questi parametri get***Params non vanno settati a false altrimenti la chiamata DB va in errore
    const
          getAnagraficaPlacementParams = ( dettaglio === 'PLACEMENTS' ) ? true : ''
        , getAnagraficaAdUnitParams    = ( dettaglio === 'AD_UNITS'   ) ? true : ''
        , getAnagraficaLineItemParams  = ( dettaglio === 'LINE_ITEMS' ) ? true : ''
    ;
    
    /* #region Format */
        const
             sGAMurl               = config.GAM_URL
            ,formatDate            = (val) => utils.formatDate(val)
            ,formatDateExcel       = (val) => utils.formatDateTime( val, { input: 'YYYYMMDD', output: 'DD/MM/YYYY' })
            ,formatDateCheckBox    = (val) => !val ? '' : utils.formatDateTime(val, { input: 'YYYYMMDDHHmmss', output: 'D MMM YYYY HH:mm', useTimezone: true, fromNow: true })
            ,formatZero            = (val) => val === 0 ? 'zero' : ''
            ,formatZeroFistColGr   = (val) => val === 0 ? 'zero firstCol' : 'firstCol'
            ,formatNum0dec         = (val) => utils.formatNumberWithOptions(val, { nOuputDecimals: 0 })
            ,formatNum1decPerc     = (val) => { return !(+val) ? '' : `${ utils.formatNumberWithOptions(val, { nOuputDecimals: 1 }) } %` }
            ,formatImp0decPerc     = (val, oRow ) => `${utils.formatNumberWithOptions(val, { nOuputDecimals: 0 })} ${ asTypeInPerc.includes( oRow.LINE_ITEM_TYPE.trim() ) ? '%' : '' }`
            ,formatPercentage      = (val, oRow ) => <div className='resizeIndicator'><AnagrafichePercentuale perc={oRow} /></div>
            ,formatCheckFoglia     = (val) => val === 1 ? <div className='iconWrap'><CheckIcon className={"textGrey"} /></div> : null
            ,formatLineItemLink    = (val, oRow ) => <a href={ sGAMurl + oRow.ACCOUNT_CODE +`#delivery/line_item/detail/line_item_id=${ val }`}  target='_blank' className='myExternalLinkID' rel='noreferrer'>{val}</a>
            ,formatPlacementLink   = (val, oRow ) => <a href={ sGAMurl + oRow.ACCOUNT_CODE +`#inventory/placement/detail/placement_id=${ val }`} target='_blank' className='myExternalLinkID' rel='noreferrer'>{val}</a>
            ,formatAdUnitLink      = (val, oRow ) => <a href={ sGAMurl + oRow.ACCOUNT_CODE +`#inventory/ad_unit/detail/ad_unit_id=${ val }`}     target='_blank' className='myExternalLinkID' rel='noreferrer'>{val}</a>
            ,formatLinkContending  = (val, oRow) =>  val > 0 ? <Tooltip title='Contending Line Items' placement='left' arrow followCursor>
                                                <Link to={`/anagrafica/${ oRow.ACCOUNT_DESC }/${ oRow.ACCOUNT_CODE }/LINE_ITEMS/${ oRow.LINE_ITEM_ID }?origin=contending`} onClick={ () => { localStorage.setItem( 'sContendingName', oRow.LINE_ITEM_NAME )}} className="link myShadow numberHover" >
                                                    <span>{ val }</span>
                                                </Link></Tooltip> : null
            ,formatLinkPlaLineItem = (val, oRow) => val > 0 ? <Tooltip title='Line Items collegati' placement='left' arrow followCursor>
                                                <Link to={`/anagrafica/${ oRow.ACCOUNT_DESC }/${ oRow.ACCOUNT_CODE }/LINE_ITEMS/${ oRow.PLACEMENT_ID }?origin=placement`} onClick={ () => { localStorage.setItem( 'sPlacementChildName', oRow.PLACEMENT_NAME )}} className="link myShadow numberHover" target='_blank'>
                                                    <span className='textGrey'>{ val }</span>
                                                </Link></Tooltip> : null
            ,formatLinkADULineItem = (val, oRow) => val > 0 ? <Tooltip title='Line Items collegati' placement='left' arrow followCursor>
                                                <Link to={`/anagrafica/${ oRow.ACCOUNT_DESC }/${ oRow.ACCOUNT_CODE }/LINE_ITEMS/${ oRow.AD_UNIT_ID }?origin=adUnit`} onClick={ () => { localStorage.setItem( 'sAdUnitChildName', oRow.AD_UNIT_NAME )}} className="link myShadow numberHover" target='_blank'>
                                                    <span className='textGrey'>{ val }</span>
                                                </Link></Tooltip> : null
            ,formatLinkLineItemPla = (val, oRow) =>  val > 0 ? <Tooltip title='Placements collegati' placement='left' arrow followCursor>
                                                <Link to={`/anagrafica/${ oRow.ACCOUNT_DESC }/${ oRow.ACCOUNT_CODE }/PLACEMENTS/${ oRow.LINE_ITEM_ID }?origin=lineItem`} onClick={ () => { localStorage.setItem( 'sLineItemContainerName', oRow.LINE_ITEM_NAME )}} className="link myShadow numberHover" target='_blank'>
                                                    <span className='textGrey'>{ val }</span>
                                                </Link></Tooltip> : null
            ,formatLinkAdUnitPla   = (val, oRow) => val > 0 ? <Tooltip title='Placements collegati' placement='left' arrow followCursor>
                                                <Link to={`/anagrafica/${ oRow.ACCOUNT_DESC }/${ oRow.ACCOUNT_CODE }/PLACEMENTS/${ oRow.AD_UNIT_ID }?origin=adUnit`} onClick={ () => { localStorage.setItem( 'sAdUnitChildName', oRow.AD_UNIT_NAME )}} className="link myShadow numberHover" target='_blank'>
                                                    <span className='textGrey'>{ val }</span>
                                                </Link></Tooltip> : null
            ,formatLinkLineItemADU = (val, oRow) =>  val > 0 ? <Tooltip title='Ad Units collegate' placement='left' arrow followCursor>
                                                <Link to={`/anagrafica/${ oRow.ACCOUNT_DESC }/${ oRow.ACCOUNT_CODE }/AD_UNITS/${ oRow.LINE_ITEM_ID }?origin=lineItem`} onClick={ () => { localStorage.setItem( 'sLineItemContainerName', oRow.LINE_ITEM_NAME )}} className="link myShadow numberHover" target='_blank'>
                                                    <span className='textGrey'>{ val }</span>
                                                </Link></Tooltip> : null
            ,formatLinkPlaADUnit   = (val, oRow) =>  val > 0 ? <Tooltip title='Ad Units collegate' placement='left' arrow followCursor>
                                                <Link to={`/anagrafica/${ oRow.ACCOUNT_DESC }/${ oRow.ACCOUNT_CODE }/AD_UNITS/${ oRow.PLACEMENT_ID }?origin=placement`} onClick={ () => { localStorage.setItem( 'sPlacementContainerName', oRow.PLACEMENT_NAME )}} className="link myShadow numberHover" target='_blank'>
                                                    <span className='textGrey'>{ val }</span>
                                                </Link></Tooltip> : null
            ,formatLink            = (val, oRow) => <Tooltip title={ getAnagraficaLineItemParams ? 'Storico' : 'Storico / Forecast'} placement='left' arrow followCursor>
                                                <Link to={`/anagrafica/${ oRow.ACCOUNT_DESC }/${ oRow.ACCOUNT_CODE }/${ dettaglio }/${ val }/dettaglio`} className='link myShadow' target='_blank' >
                                                    <DoubleArrowRightIcon />
                                                </Link ></Tooltip>
            ,formatAlarmCheck = (cont) =>
                (val, oRow) => {
                    let jsonParsed = [];
                    try { jsonParsed = JSON.parse(val || "[]"); } catch(err) { jsonParsed = []; }
                    let obj = jsonParsed?.find(o => +o?.A === cont ) || {};
                    // console.log(Object.keys(obj).length > 0)
                    // console.log((JSON.parse(val || "[]")?.find(o => +o.A === cont )))
                    // console.log(val);
                    return val && Object.keys(obj).length > 0
                           && <Checkbox id        = { oRow?.LINE_ITEM_ID + '' }
                                        checked   = { obj?.['C'] === 'Y' }
                                        onClick   = {(event) => { event.stopPropagation() }}
                                        onChange  = {(event) => { setAlarmVerified(cont, oRow) }}
                                        title     = { formatDateCheckBox(obj?.['D']) }
                                        className = { `checkbox-custom ${obj?.['U'] === kUser ? 'user-checked' : '' }`}/>}
            ,formatAlarmExcel = (cont) =>
                    (val) => {
                        let jsonParsed = [];
                        try { jsonParsed = JSON.parse(val || "[]"); } catch(err) { jsonParsed = []; }
                        let obj = jsonParsed?.find(o => +o?.A === cont ) || {};
                        return val && Object.keys(obj).length > 0 ?
                                                    ( obj?.['C'] === 'Y' ?
                                                        'V' : 'N' )
                                : ''
                    }
            ,faoAlarmColumn = () => {
                let aoAlarmColumn = [];
                for (let cont = 1; cont <= 11; cont ++) {
                    // if ( aAlarmsHeader.find(o => +o === cont)) {
                    if ( aoAlarmsHeader[ cont ]) {
                        aoAlarmColumn.push({ 
                             name            : 'ALERTS' 
                            ,title           : 'A' + (cont + '').padStart(2,'0')
                            ,group           : 'Allarmi'
                            ,width           : 31
                            ,format          : formatAlarmCheck(cont)
                            ,formatExcel     : formatAlarmExcel(cont)
                            ,notSortable     : true
                            ,additionalClass : () => 'A' + (cont + '').padStart(2,'0') + ( (cont === 1) ? ' firstCol' : (cont  === 11) ? ' lastCol' : '' )
                            ,headerTooltip   : aoAlarmsHeader[ cont ]
                            //  ,selectOptions: filterAllarmiAnagrafiche 
                        })
                    }
                }
                return aoAlarmColumn
            }
        ;
    /* #endregion */



    const
         [ loading                   ,setLoading               ] = useState(false)
        ,[ onReload                  ,set_onReload             ] = useState(null) // State per triggerare il lancio della chiamata
        ,[ showConfirmDialog         ,setShowConfirmDialog     ] = useState(true)
        
        ,[ aoAnagrafica              ,set_aoAnagrafica         ] = useState([])
        ,[ aoAnagraficaHeaders       ,set_aoAnagraficaHeaders  ] = useState([])
        ,[ oOrderClause              ,set_oOrderClause         ] = useState({ name: '', sort: '' })
        ,[ oPaginationOption         ,set_oPaginationOption    ] = useState({ nTotRecord: 0, pFirstRecord: 1, pNumRecords: nNumRecords })
        ,[ hTitle                    ,set_hTitle               ] = useState(<></>)
        ,[ sExportTitle              ,set_sExportTitle         ] = useState('')
        ,[ sVerifiedTarget           ,set_sVerifiedTarget      ] = useState('')
    ;

    // Function per settare il flag allarme line item verificato
    const setAlarmVerified = async (idAlarm, obj) => {
        setLoading(true);
        try {
            set_sVerifiedTarget( obj.LINE_ITEM_ID + '' );
            
            let jsonParsed = [];
            try { jsonParsed = JSON.parse(obj?.ALERTS || "[]"); } catch(err) { jsonParsed = []; }
            
            const params = {
                pLineItemId:    obj?.LINE_ITEM_ID || ""
               ,pAlertId:       idAlarm
               ,pAlertStatus:   (jsonParsed?.find(o => +o?.A === idAlarm )?.['C'] === 'N') ? 'Y' : 'N'
               ,pAlertsJsonAll: obj?.ALERTS || "[{}]"
            };
            // console.log('params: ', params);

            const response = await axios.post( config.API_URL + '/set-alerts-verified', null, { headers, params } );
            const { nRetVal, vErrorMessageDesc } = response.data;

            if ( ( response.status === 200 ) && ( +nRetVal > 0 ) && ( !vErrorMessageDesc ) ) {
                set_oNotifyOptions({ message: `Operazione eseguita correttamente`, severity: 'success', timeout: 4 });
            } else {
                console.error(`Errore ${ vErrorMessageDesc || '' }`);
                set_oNotifyOptions({ message: <><span>Operazione fallita su LineItem {(obj?.LINE_ITEM_ID || '')}</span>
                                           <br/><span>{obj?.LINE_ITEM_NAME}</span>
                                           <br/><span>{ vErrorMessageDesc || '' }</span></>, severity:'error' });
            }
        } catch ( err ) {
            console.error('ERR 134: Failed', err);
            set_oNotifyOptions({ message: <><span>Operazione fallita su LineItem {(obj?.LINE_ITEM_ID || '')}</span><br/><span>{obj?.LINE_ITEM_NAME}</span></> , severity:'error' });
        }
        set_onReload(!onReload);
    }

    // Function per ottenere dati secondari oltre all'anagrafica
    const getInfo = async () => {
        bDebug && console.log('Dentro GetInfo');
        try {
            // console.log('oFilterParams: ', oFilterParams );
            let response = await axios.get(
                config.API_URL + '/get',
                {
                    headers,
                    params: { getAnagraficheParams: true, sSubcategoria: dettaglio, pAccountDesc: oFilterParams.pAccountDesc?.toLowerCase() || account }
                }
            );
            if ( response?.status === 200 && response?.data?.aoRecords ) {
                let aoRecords = response.data.aoRecords || [];
                bDebug && console.log(aoRecords[0].MAX_LAST_EXECUTION);
                set_dLastUpdate( aoRecords[0].MAX_LAST_EXECUTION || '' );
            }

        } catch(err) {
            console.error('ERR 190: Failed GetAnagrafiche: ', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' })
        }
    };

	// Function per formattare la tabella
    const getDetailTable = () => {
        bDebug && console.log('Dentro GetDetailTable');
        setLoading(true)
        // !!! ToDo forse il conteggio di elementi collegati posso mostrarlo anche se non sono in idDettaglio, DA VERIFICARE
        /* #region set_aoAnagraficaHeader */
            if( dettaglio === 'PLACEMENTS' ) {
                set_aoAnagraficaHeaders([
                     { name: 'PLACEMENT_ID'            ,title: 'Placement ID'             ,width: 110  ,format: formatPlacementLink    ,isNum:   true  ,isUniqueKeyForRow: true   ,filterOriginalValue: true }
                    ,{ name: 'PLACEMENT_NAME'          ,title: 'Placement'                ,width: 500                                  ,tooltip: true  }
                    ,{ name: 'NUM_LINE_ITEMS'          ,title: 'Line Items'               ,width: 74   ,format: formatLinkPlaLineItem  ,isNum:   true  ,additionalClass: () => ( ( isDemo || isSole ) ? 'sticky3' : 'sticky2alt' ) ,notSortable: true }
                    ,{ name: 'NUM_AD_UNITS'            ,title: 'Ad Units'                 ,width: 74   ,format: formatLinkPlaADUnit    ,isNum:   true  ,additionalClass: () => ( ( isDemo || isSole ) ? 'sticky2' : 'sticky1')    ,notSortable: true }
,( ( isDemo || isSole ) && { name: 'PLACEMENT_ID'      ,title: ''                         ,width: 45   ,format: formatLink                             ,additionalClass: () => 'sticky1' ,notSortable: true ,noExport: true } )
                    /* PLACEMENT_ID, PLACEMENT_NAME, NUM_LINE_ITEMS, NUM_AD_UNITS */
                ]);

            } else if ( dettaglio === 'LINE_ITEMS' ) {
                let aoAnagHead = [
                     { name: 'LINE_ITEM_ID'            ,title: 'Line Item ID'             ,width: 100  ,format: formatLineItemLink     ,isNum:   true  ,isUniqueKeyForRow: true   ,filterOriginalValue: true  ,additionalClass: formatZero  }
                    ,{ name: 'LINE_ITEM_NAME'          ,title: 'Line Item'                ,width: 600                                  ,tooltip: true  }
                    ,{ name: 'ORDER_NAME'              ,title: 'Ordine'                   ,width: 350                                  ,tooltip: true  }
                    ,{ name: 'BEGIN_DATE'              ,title: 'Data Inizio'              ,width: 90   ,format: formatDate             ,formatExcel: formatDateExcel ,additionalClass: formatZero }
                    ,{ name: 'END_DATE'                ,title: 'Data Fine'                ,width: 90   ,format: formatDate             ,formatExcel: formatDateExcel ,additionalClass: formatZero }
                    ,{ name: 'STATUS'                  ,title: 'Status'                   ,width: 110  }
                    ,{ name: 'PESO'                    ,title: 'Peso'                     ,width: 50   ,isNum:   true }
                    ,{ name: 'LINE_ITEM_TYPE'          ,title: 'Tipo'                     ,width: 110  }
                    ,{ name: 'PRIORITY'                ,title: 'Priorità'                 ,width: 50                                   ,isNum:   true  ,additionalClass: formatZero }
                    ,...faoAlarmColumn()
                    ,{ name: 'IMPRESSIONS_SCHEDULED'   ,title: 'Imps Scheduled'           ,width: 135  ,format: formatImp0decPerc    /*,group: 'Impressions'*/  ,isNum:   true  ,additionalClass: formatZeroFistColGr }
                    ,{ name: 'IMPRESSIONS_DELIV'       ,title: 'Imps Delivered'           ,width: 135  ,format: formatNum0dec        /*,group: 'Impressions'*/  ,isNum:   true  ,additionalClass: formatZero }
                ];
                let checkOrigin = ( origin === 'contending' || origin === '5' );
                
                ( isDemo || isSole ) && aoAnagHead.push(...[
                     { name: 'DELIVERY_ESTIMATED_PERC' ,title: 'Check Inventory'          ,width: 80   ,format: formatNum1decPerc     /*,group: 'Impressions'*/ ,isNum:   true  ,additionalClass: formatZero      ,headerTooltip: utils.mapTooltip.CheckInventory       }
                    ,{ name: 'ACTUAL_PROG_PERC'        ,title: 'Progressivo attuale'      ,width: 80   ,format: formatNum1decPerc     /*,group: 'Impressions'*/ ,isNum:   true  ,additionalClass: formatZero      ,headerTooltip: utils.mapTooltip.Progressivoattuale   }
                    ,{ name: 'OBJ_PROG_PERC'           ,title: 'Progressivo obiettivo'    ,width: 80   ,format: formatNum1decPerc     /*,group: 'Impressions'*/ ,isNum:   true  ,additionalClass: formatZero      ,headerTooltip: utils.mapTooltip.Progressivoobiettivo }
                    ,{ name: 'PACING'                  ,title: 'Pacing'                   ,width: 80   ,format: formatNum1decPerc     /*,group: 'Impressions'*/ ,isNum:   true  ,additionalClass: formatZero      ,headerTooltip: utils.mapTooltip.Pacing               }
                    ,{ name: 'INDICATOR'               ,title: 'Indicatore'               ,width: 100  ,format: formatPercentage      /*,group: 'Impressions'*/ ,isNum:   true  ,additionalClass: () => 'lastCol' ,notSortable: true ,noExport: true }
  ,(  checkOrigin && { name: 'CONTENDED_IMPRESSIONS'   ,title: 'Impressions contese'      ,width: 135  ,format: formatNum0dec                                   ,isNum:   true  ,additionalClass: () => 'sticky4' ,notSortable: true } )
  ,( !checkOrigin && { name: 'CONTENDING_COUNT'        ,title: 'Contending'               ,width: 72   ,format: formatLinkContending                            ,isNum:   true  ,additionalClass: () => 'sticky4' ,notSortable: true } )
                 // ,{ name: 'NUM_PLACEMENTS'          ,title: 'Placements'               ,width: 74   ,format: formatLinkLineItemPla                  ,additionalClass: () => 'sticky3' ,notSortable: true }
                 // ,{ name: 'NUM_AD_UNITS'            ,title: 'Ad Units'                 ,width: 74   ,format: formatLinkLineItemADU                  ,additionalClass: () => 'sticky2' ,notSortable: true }
                ]);
                aoAnagHead.push(...[
                     { name: 'NUM_PLACEMENTS'          ,title: 'Placements'               ,width: 74   ,format: formatLinkLineItemPla  ,isNum:   true  ,additionalClass: () => ( ( isDemo || isSole ) ? 'sticky3' : 'sticky2alt')  ,notSortable: true }
                    ,{ name: 'NUM_AD_UNITS'            ,title: 'Ad Units'                 ,width: 74   ,format: formatLinkLineItemADU  ,isNum:   true  ,additionalClass: () => ( ( isDemo || isSole ) ? 'sticky2' : 'sticky1')     ,notSortable: true }
  ,(  ( isDemo || isSole ) && { name: 'LINE_ITEM_ID'   ,title: ''                         ,width: 45   ,format: formatLink                             ,additionalClass: () => 'sticky1' ,notSortable: true ,noExport: true } )
                ]);
                set_aoAnagraficaHeaders(aoAnagHead);

            } else if ( dettaglio === 'AD_UNITS' ) {
                set_aoAnagraficaHeaders([
                     { name: 'AD_UNIT_ID'              ,title: 'AD Unit ID'               ,width: 110  ,format: formatAdUnitLink       ,isNum:   true  ,isUniqueKeyForRow: true   ,filterOriginalValue: true  ,additionalClass: formatZero }
                    ,{ name: 'AD_UNIT_NAME'            ,title: 'AD Unit'                  ,width: 600                                  ,tooltip: true  }
                    ,{ name: 'LIVELLO'                 ,title: 'Livello'                  ,width: 55                                   ,isNum:   true  }
                    ,{ name: 'FOGLIA'                  ,title: 'Foglia'                   ,width: 55   ,format: formatCheckFoglia      ,isNum:   true  }
                    ,{ name: 'AD_UNIT_SIZES'           ,title: 'Formati'                  ,width: 500                                  ,isNum:   true  ,additionalClass: formatZero      }
                    ,{ name: 'NUM_LINE_ITEMS'          ,title: 'Line Items'               ,width: 74   ,format: formatLinkADULineItem  ,isNum:   true  ,additionalClass: () => ( ( isDemo || isSole ) ? 'sticky3' : 'sticky2alt') ,notSortable: true }
                    ,{ name: 'NUM_PLACEMENTS'          ,title: 'Placements'               ,width: 74   ,format: formatLinkAdUnitPla    ,isNum:   true  ,additionalClass: () => ( ( isDemo || isSole ) ? 'sticky2' : 'sticky1')    ,notSortable: true }
  ,( ( isDemo || isSole ) && { name: 'AD_UNIT_ID'      ,title: ''                         ,width: 45   ,format: formatLink                             ,additionalClass: () => 'sticky1' ,notSortable: true ,noExport: true } )
               ]);
            }
        /* #endregion */
        
        // set_nAnagLength(aoAnagrafica.length || 0);
        setLoading(false);
    };

    // Function per ottenere i dati, il corpo dell'anagrafica
    const getAnagDetailsFromDB = async ({ oCol = {}, oPag = {} } = { oCol: {}, oPag: {} }) => {
        bDebug && console.log('Dentro GetAnagDetailsFromDB');
        setLoading(true);
        let bColParam = oCol.name && oCol.sort;

        let sSortOption = bColParam ? 
                            `${ oCol.name } ${ oCol.sort }` 
                           : oOrderClause.name ? 
                                `${ oOrderClause.name } ${ oOrderClause.sort }` : '';
        if ( bColParam ) {
            set_oOrderClause({ ...oCol});
            set_oPaginationOption({ ...oPaginationOption, pFirstRecord: 1 })
        }

        let oPagOpt = oPag.pFirstRecord ?
                        { ...oPag }
                       : oPaginationOption.pFirstRecord ?
                            { ...oPaginationOption } : { pFirstRecord: 1, pNumRecords: 500 };
        // if ( oPag.pFirstRecord && oPag.pNumRecords ) {
        //     set_oPaginationOption({...oPag});
        // }

        // console.log('oCol: ', oCol)
        // console.log('getAnagDetailsFromDB( sSortOption ): ', sSortOption.length );
        // console.log('sOrderClause: ', oOrderClause );
        // console.log('account: '     , account );

        // Chiamata per ottenere i valori da inserire nella tabella
        try {            
            const params = {
                 ...( getAnagraficaPlacementParams && { getAnagraficaPlacementParams : true })
                ,...( getAnagraficaAdUnitParams    && { getAnagraficaAdUnitParams    : true })
                ,...( getAnagraficaLineItemParams  && { getAnagraficaLineItemParams  : true })
                ,oFilterParams: { 
                     ...( !idDettaglio && oFilterParams )
                    ,...(( getAnagraficaLineItemParams &&  ( origin === 'contending' || origin === '5' )) && {
                            // Chiamata Line_Item Contending (origin è l'unico che mi permette di capire se sono in contending in quanto idDettaglio viene sfruttato anche per trovare i line item collegati al placement(idDettaglio))
                              pLineItemId           : idDettaglio 
                            , pLineItemName         : ''
                            , pPlacementId          : ''
                            , pAdUnitId             : ''
                            , pDataInizio           : ''
                            , pDataFine             : ''
                            , pOrderName            : ''
                            , pLineItemTypeList     : ''
                            , pStatusList           : ''
                            , pAlerts               : ''
                            , pPacing               : ''
                            , pPacingOper           : '='
                            , pDeliveryEstim        : ''
                            , pDelivOper            : '='
                            , pPriorityList         : '' 
                            , pContendingLineItems  : 'Y'
                            , pAccountDesc          : account
                        //   , pContendingCount       : ''
                        //   , pContendingOper        : '='
                        })
                    ,...(( getAnagraficaLineItemParams && idDettaglio && origin === 'placement' ) && {
                            // Chiamata Line_Item contenenti il Placement in idDettaglio
                              pLineItemId           : '' 
                            , pLineItemName         : ''
                            , pPlacementId          : idDettaglio
                            , pAdUnitId             : ''
                            , pDataInizio           : ''
                            , pDataFine             : ''
                            , pOrderName            : ''
                            , pLineItemTypeList     : ''
                            , pStatusList           : ''
                            , pAlerts               : ''
                            , pPacing               : ''
                            , pPacingOper           : '='
                            , pDeliveryEstim        : ''
                            , pDelivOper            : '='
                            , pPriorityList         : '' 
                            , pAccountDesc          : account
                            , pContendingLineItems  : ''
                        //   , pContendingCount       : ''
                        //   , pContendingOper        : '='
                        })
                    ,...(( getAnagraficaLineItemParams && idDettaglio && origin === 'adUnit' ) && {
                            // Chiamata Line_Item contenenti il Placement in idDettaglio
                              pLineItemId           : '' 
                            , pLineItemName         : ''
                            , pPlacementId          : ''
                            , pAdUnitId             : idDettaglio
                            , pDataInizio           : ''
                            , pDataFine             : ''
                            , pOrderName            : ''
                            , pLineItemTypeList     : ''
                            , pStatusList           : ''
                            , pAlerts               : ''
                            , pPacing               : ''
                            , pPacingOper           : '='
                            , pDeliveryEstim        : ''
                            , pDelivOper            : '='
                            , pPriorityList         : '' 
                            , pAccountDesc          : account
                            , pContendingLineItems  : ''
                        //   , pContendingCount       : ''
                        //   , pContendingOper        : '='
                        })
                    ,...(( getAnagraficaPlacementParams && idDettaglio && origin === 'lineItem' ) && {
                            // Chiamata Placement Appartenenti al Line_Item_ID
                              pPlacementId          : '' 
                            , pPlacementName        : ''
                            , pLineItemId           : idDettaglio 
                            , pLineItemName         : '' 
                            , pAdUnitId             : ''
                            , pAccountDesc          : account
                            // , pAdUnitName           : ''
                        })
                    ,...(( getAnagraficaPlacementParams && idDettaglio && origin === 'adUnit' ) && {
                            // Chiamata Placement contenenti l' Ad_Unit_ID
                              pPlacementId          : '' 
                            , pPlacementName        : '' 
                            , pLineItemId           : '' 
                            , pLineItemName         : ''
                            , pAdUnitId             : idDettaglio
                            , pAccountDesc          : account
                            //         , pAdUnitName           : ''
                        })
                    ,...(( getAnagraficaAdUnitParams    && idDettaglio && origin === 'lineItem' ) && {
                            // Chiamata AdUnit Appartenenti al PlacementId
                              pAdUnitId             : ''
                            , pAdUnitName           : ''
                            , pPlacementId          : ''
                            , pPlacementName        : ''
                            , pLineItemId           : idDettaglio
                            , pLineItemName         : ''
                            , pSitoName             : ''
                            , pFlagForecast         : ''
                            , pFlagFoglia           : ''
                            , pSizes                : ''
                            , pAccountDesc          : account
                        })
                    ,...(( getAnagraficaAdUnitParams    && idDettaglio && origin === 'placement' ) && {
                            // Chiamata AdUnit Appartenenti al PlacementId
                              pAdUnitId             : ''
                            , pAdUnitName           : ''
                            , pPlacementId          : idDettaglio
                            , pPlacementName        : ''
                            , pLineItemId           : ''
                            , pLineItemName         : ''
                            , pSitoName             : ''
                            , pFlagForecast         : ''
                            , pFlagFoglia           : ''
                            , pSizes                : ''
                            , pAccountDesc          : account
                        })
                        
                    , pOrderByClause : sSortOption
                    , pFirstRecord: bColParam ? 1 : oPagOpt.pFirstRecord
                    , pNumRecords: oPagOpt.pNumRecords
                }
            };

            // console.log(params)
            let response = await axios.get( config.API_URL + '/get', { headers, params } );
            // console.table(response.data.aoRecords.slice(0,2))
            if ( response && ( response?.status === 200 ) && response?.data?.aoRecords?.length && Array.isArray(response.data.aoRecords)) {
                set_aoAnagrafica([...response.data.aoRecords]);
                set_oPaginationOption({ ...oPaginationOption
                                         , pFirstRecord: bColParam ? 1 : oPagOpt.pFirstRecord
                                         , nTotRecord: ( response.data.aoRecords[0].CC_TOT || 0 )});
                bDebug && console.log(response.data.aoRecords);
                // set_dLastUpdate( dLastUpdate || '' );

            } else {
                setLoading(false);
            }

        } catch(err) {
            console.error('ERR 191: Failed getAnagDetailsFromDB: ', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
            setLoading(false);
        }
    };

    const handleScroll = (sModifyTarget) => {
        let element = document.getElementById(sModifyTarget);

        if (sModifyTarget && element) {
            element.scrollIntoView({ behavior: "auto", block: "center", inline: 'center' });
            sVerifiedTarget && set_sVerifiedTarget('')
        }
    };

    // Use effect per intercettare l'atterraggio in pagina da allarmi
    useEffect(() => {
        if (idDettaglio) {
            // console.log('useEffectIdDettaglio: ', onReload)
            setLoading(true);
            set_onReload(!onReload);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ idDettaglio ])

    // Abbiamo deciso di lasciare sempre il popup filtri aperto in fase di atterraggio pagina (no idDettaglio)
    useEffect( () => {
        // Con questa UseEffect controllo appena atterrato nella pagina se vi sono già dei filtri settati per il dettaglio selezionato
        // , nel qual caso nascondo il popup Filtri e lancio immediatamente la chiamata
        // bDebug && console.log('UseEffect Iniziale oFilterParams');
        // setLoading(true);
        // let bHasValue = false;
        // bDebug && console.log( 'oFilterParams: ', oFilterParams );
        // // Nel controllo non devo inserire gli ID delle altre anagrafiche perchè non verranno mai settati nei filtri
        // if ( getAnagraficaLineItemParams ) {
        //     bHasValue = !!( oFilterParams.pLineItemName || oFilterParams.pLineItemId || oFilterParams.pOrderName || oFilterParams.pStatusList || oFilterParams.pLineItemTypeList || oFilterParams.pPacing || oFilterParams.pDeliveryEstim || oFilterParams.pContendingCount || oFilterParams.pPriorityList || oFilterParams.pDataFine || oFilterParams.pDataInizio );
        // } else if ( getAnagraficaAdUnitParams ) {
        //     bHasValue = !!( oFilterParams.pAdUnitId || oFilterParams.pAdUnitName || oFilterParams.pLineItemName || oFilterParams.pPlacementName || oFilterParams.pSitoName || oFilterParams.pFlagForecast || oFilterParams.pFlagFoglia || oFilterParams.pSizes );
        // } else if ( getAnagraficaPlacementParams ) {
        //     bHasValue = !!( oFilterParams.pPlacementId || oFilterParams.pPlacementName || oFilterParams.pLineItemName ); // || oFilterParams.pAdUnitName
        // }

        // if ( bHasValue ) {
            // setShowConfirmDialog(false);
            // set_onReload(!onReload);
        // } else {
            // setShowConfirmDialog(true);
            setLoading(false);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ oFilterParams ]);

    // onReload viene settato da: [ UseEffect Iniziale oFilterParams, Button onReload, KeyPress: 'Enter' ]
    useEffect( () => {
        bDebug && console.log('UseEffect onReload');
        if ( onReload != null ) {
            // console.log('Dentro UseEffect onReload');
            setLoading(true);
            set_dLastUpdate('');
            set_oNotifyOptions(null);
            setShowConfirmDialog(false);
            set_aoAnagrafica([]);
            set_aoAnagraficaHeaders([]);
            getInfo();
            getAnagDetailsFromDB();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);

    useEffect( () => {
        if ( loading === false &&  sVerifiedTarget) {
            bDebug && console.log('useEffectLoading');
            handleScroll( sVerifiedTarget );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ loading ]);

    // aoAnagrafica != [] viene settato da: [ 'getAnagDetailsFromDB' ]
    useEffect( () => {
        bDebug && console.log('UseEffect aoAnagrafica');
        if ( onReload != null && aoAnagrafica.length > 0 ) {
            
            getDetailTable();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ aoAnagrafica ]);

    const myKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            set_onReload(!onReload);
        }
    };

    const createTitle = () => {
        return  !idDettaglio
            ? { title: <span className='myBold'>{ utils.formatNamePage(dettaglio) }</span>, subTitle: null, exportTitle: `${utils.formatNamePage(dettaglio)}` }
            : ( getAnagraficaLineItemParams &&  ( origin === 'contending' || origin === '5' )) 
            ? { title: <><span className='myBold'>Contending</span> del <span className='myBold'>Line Item {idDettaglio}</span></>, subTitle: isDemo ? '' : sContendingName, exportTitle: `Contending del Line Item ${ idDettaglio }` }
            : ( getAnagraficaLineItemParams && idDettaglio && origin === 'placement' )
            ? { title: <><span className='myBold'>Line Items</span> contenenti il <span className='myBold'>Placement {idDettaglio}</span></>, subTitle: isDemo ? '' : sPlacementChildName, exportTitle: `Line Items contenenti il Placement ${ idDettaglio }` }
            : ( getAnagraficaLineItemParams && idDettaglio && origin === 'adUnit' )
            ? { title: <><span className='myBold'>Line Items</span> contenenti l'<span className='myBold'>Ad Unit {idDettaglio}</span></>, subTitle: isDemo ? '' : sAdUnitChildName, exportTitle: `Line Items contenenti l'Ad Unit ${ idDettaglio }` }
            : ( getAnagraficaPlacementParams && idDettaglio && origin === 'lineItem' )
            ? { title: <><span className='myBold'>Placements</span> appartenenti al <span className='myBold'>Line Item {idDettaglio}</span></>, subTitle: isDemo ? '' : sLineItemContainerName, exportTitle: `Placements appartenenti al Line Item ${ idDettaglio }` }
            : ( getAnagraficaPlacementParams && idDettaglio && origin === 'adUnit' )
            ? { title: <><span className='myBold'>Placements</span> contenenti l'<span className='myBold'>Ad Unit {idDettaglio}</span></>, subTitle: isDemo ? '' : sAdUnitChildName, exportTitle: `Placements contenenti l'Ad Unit ${ idDettaglio }` }
            : ( getAnagraficaAdUnitParams && idDettaglio && origin === 'lineItem' )
            ? { title: <><span className='myBold'>Ad Unit</span> appartenenti al <span className='myBold'>Line Item {idDettaglio}</span></>, subTitle: isDemo ? '' : sLineItemContainerName, exportTitle: `Ad Unit appartenenti al Line Item ${ idDettaglio }` }
            : ( getAnagraficaAdUnitParams && idDettaglio && origin === 'placement' )
            ? { title: <><span className='myBold'>Ad Unit</span> appartenenti al <span className='myBold'>Placement {idDettaglio}</span></>, subTitle: isDemo ? '' : sPlacementContainerName, exportTitle: `Ad Unit appartenenti al Placement ${ idDettaglio }` }
            : null;
    }

    useEffect(() => {
        const sAccount  = ( isDemo || isSole ) ? '' : 'Account ' + account.toUpperCase() + ' - ';
        const total    = `( Visualizzati: ${ aoAnagrafica?.length || 0 } )`;
        const { title, subTitle, exportTitle } = createTitle();
        set_sExportTitle( `${ ( isDemo || isSole ) ? '' : ( account.toUpperCase() + ' - ' ) }${ exportTitle }` );

        set_hTitle(<span className='flexyTitle'>
                       <span>
                           { sAccount }{ title } { total }
                       </span>
                       {
                           subTitle && <CustomNoWidthTooltip title={ subTitle } placement='bottom' arrow><span className='dettaglioDescription'>{ subTitle }</span></CustomNoWidthTooltip>
                       }
                   </span>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[aoAnagrafica]);


    return (
        <div className='component-container anagraphDetail' tabIndex={0} onKeyDown={ (e) => myKeyPress(e) }/*  */>
            <div className="component-card anagDetail1">
                <>
                    <div className="component-options-wrapper-anagraphDetail-title">
                        
                        <div  className="component-options-wrapper-title">
                            <div className="component-options-wrapper-subtitle">
                                { !idDettaglio
                                  
                                    ?  <Link to="/anagrafiche" className='link' >
                                        <Button className="backButton" variant="outlined" startIcon={<DoubleArrowLeftIcon className='myIcon'/>} >
                                            <span>anagrafiche</span>
                                        </Button>
                                    </Link>
                                  
                                    : ( origin === 'placement' ) 
                                      
                                        ? <Link to={`/anagrafica/${ account }/${ accountcode }/PLACEMENTS`} className="link" >
                                            <Button className="backButton link" variant="outlined" onClick={ () => set_onReload(!onReload) } startIcon={<DoubleArrowLeftIcon className='myIcon'/>} >
                                                <span>anagrafica placements</span>
                                            </Button>
                                        </Link>
                                      
                                        : ( origin === 'adUnit' )
                                          
                                            ?  <Link to={`/anagrafica/${ account }/${ accountcode }/AD_UNITS`} className="link" >
                                                <Button className="backButton link" variant="outlined" onClick={ () => set_onReload(!onReload) } startIcon={<DoubleArrowLeftIcon className='myIcon'/>} >
                                                    <span>anagrafica ad units</span>
                                                </Button>
                                            </Link>
                                          
                                            : (( origin === 'contending' ) ||  ( origin === 'lineItem' ))
                                              
                                                ? <Link to={`/anagrafica/${ account }/${ accountcode }/LINE_ITEMS`} className="link" >
                                                    <Button className="backButton link" variant="outlined" onClick={ () => set_onReload(!onReload) } startIcon={<DoubleArrowLeftIcon className='myIcon'/>} >
                                                        <span>anagrafica line Items</span>
                                                    </Button>
                                                </Link>
                                              
                                                : <Link to={`/allarme/${ origin }`} className="link" >
                                                    <Button className="backButton link" variant="outlined" startIcon={<DoubleArrowLeftIcon className='myIcon'/>} >
                                                        <span>{`allarme ${ origin }`}</span>
                                                    </Button>
                                                </Link>
                                }
                            </div>
                            <div className="component-options-wrapper-title">
                                <span className={`titleAnagraficaDettaglio  ${dettaglio}`}>{
                                    hTitle 
                                }</span>
                            </div>
                        </div>
                        
                        <div className="component-options-wrapper-title">
                            { !idDettaglio && !loading ? <AnagrafichePopup utilParam={{ loading, setLoading, onReload, set_onReload, showConfirmDialog, setShowConfirmDialog }} /> : null } 
                            <IconButton className="reloadButton link myShadow" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ () => set_onReload(!onReload) }>
                                <CachedIcon />
                            </IconButton>
                        </div>
                        
                    </div>
                    <div className="component-options-wrapper-anagraphDetail">
                        { 
                            loading ? <CircleProgress /> :// condizioni per la presenza dei dati
                                ( Array.isArray(aoAnagrafica) && Array.isArray(aoAnagraficaHeaders) && aoAnagrafica?.length && aoAnagraficaHeaders?.length )
                                    ? <SimpleTable
                                    className         = { loading || !( Array.isArray(aoAnagrafica) && Array.isArray(aoAnagraficaHeaders) && aoAnagrafica?.length && aoAnagraficaHeaders?.length ) ? 'hiddenElement' : '' }
                                    chiave            = { 'ANAGRAFICA_DETTAGLIO' }
                                    sTableDataType    = { 'ANAGRAFICA_DETTAGLIO' }
                                    aoRows            = { aoAnagrafica }
                                    aoCols            = { aoAnagraficaHeaders }
                                    fGetRecord        = { getAnagDetailsFromDB }
                                    oSortOptions      = { { isSortable    : true
                                                          , oSort         : { ...oOrderClause } } }
                                                     //   , fSort         : getAnagDetailsFromDB } }
                                    oPaginationOption = { { isPagination : true
                                                          , oPag         : { ...oPaginationOption } }}
                                                     //   , set_oPaginationOption } } // !!! Maybe isn't necessary
        
                                    oExportOption     = { { sFileName: `Anagrafica ${ sExportTitle }` } }
                                    oHeadersOptions   = { { tooltip: true, breakline: true } }
                                    noHeight          = { true }
                                    noFilter          = { true }
                                />
                                    : ( onReload !== null)
                                        ? <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp;Nessun risultato trovato</div>
                                        : <div className="component-options-wrapper-alarmNoRecord"> Selezionare un filtro per la ricerca</div>
                        }
                    </div>
                </>
            </div>
        </div>
    )
};

export default AnagraficheDettaglio;
 