import * as React           from 'react';
import { alpha, styled }           from '@mui/material/styles';
import { InputBase
        ,Radio
        ,Switch 
        ,Tab
        ,TextField }        from '@mui/material';
import TabList              from '@mui/lab/TabList';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const CssTextField = styled(TextField)({ 
        '& label.Mui-focused': {
        color: 'var(--color-dark)',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'var(--color-dark)',
    },
    '& .MuiInput-underline:invalid': {
        borderBottomColor: 'var(--color-red)',
    },
    '& .MuiOutlinedInput-root:hover': {
        'fieldset': {
            borderColor: 'var(--color-dark)',
        }
    },
    '& .MuiOutlinedInput-root': {
        'fieldset': {
        borderColor: 'var(--color-dark)',
        },
        '&.Mui-focused fieldset': {
        borderColor: 'var(--color-dark)',
        },
        '&.Mui-disabled fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.26) !important'
        }
    }
});

const CssInputBase = styled((InputBase))({
    '& .MuiInputBase-input': {
        border: '2px solid var(--color-lightblue)',
        padding: '10px 26px 10px 12px',
        '&:focus': {
            borderColor: 'var(--color-dark)',
        },
    },
});

const CssTabList = styled((props) => (
    <TabList
        { ...props }
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
    ))({
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
          },
        '& .MuiTabs-indicatorSpan': {
            // maxWidth: 40,
            width: '100%',
            backgroundColor: 'var(--color-dark)'
        }
});

const CssTab = styled((props) => (
    <Tab {...props} />
    ))({
        fontWeight: 'bold',
        '&.Mui-selected': {
            color: 'var(--color-dark)'
        }
    }
);

const MaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '550px !important',
    fontSize: '0.8rem !important'
  },
});
const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    fontSize: '0.8rem !important'
  },
});

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: 'var(--color-dark)',
      '&:hover': {
        backgroundColor: alpha('#fc9228', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#e4cbb0',
    },
}));

const RadioCheckedIcon = styled(Radio)(({ theme }) => ({
    '& .MuiRadio-root.Mui-checked': {
      color: 'var(--color-dark)',
      '&:hover': {
        backgroundColor: alpha('#fc9228', theme.palette.action.hoverOpacity),
      },
    },
}));


export function CustomCssTextField(props) {
    return ( <> { props?.tooltiptext ? 
        <Tooltip id={`${props?.id || '' } tooltip`} title={ props?.tooltiptext || '' } placement={ props?.tooltipplacement || 'bottom' } arrow >
            <CssTextField { ...props } />
        </Tooltip> 
      : <CssTextField { ...props } />
    } </> );
};

export function CustomCssInput(props) {
    return <CssInputBase { ...props } />
};

export function CustomCssTabList(props) {
    return <CssTabList { ...props } />
};

export function CustomCssTab(props) {
    return <CssTab { ...props } />
};

export function CustomMaxWidthTooltip(props) {
    return <MaxWidthTooltip { ...props }/>
};

export function CustomNoWidthTooltip(props) {
    return <NoMaxWidthTooltip { ...props }/>
};

export function CustomDis24Switch(props) {
    return <CustomSwitch { ...props }/>
};

export function RadioDis24CheckedIcon(props) {
    return <RadioCheckedIcon { ...props } sx={{
        '&:hover': {
            backgroundColor: 'var(--color-light)',
          },
        '&.Mui-checked': {
          color: 'var(--color-dark)',
          '&:hover': {
            backgroundColor: 'var(--color-light)',
          },
        },
    }}/>
};