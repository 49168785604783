const
common = {
     NEW_USER_TEMP_PASSWORD : 'Password01!'
    ,REFRESH_TOKEN_INTERVAL : 60000 * 30 // 30 minuti
    ,GAM_URL                : 'https://admanager.google.com/'
};

const environment = {
    local: {
         REACT_APP_CLIENT_ID    : '3kmq78ti8dmnkurnmvsqiqfesl'
        ,REACT_APP_USERPOOL_ID  : 'eu-west-3_eomwt9wCx'
        ,API_URL                : 'https://discovery.api.systrategy.com/digitalanalyzer'
        ,ROOBEEK_URL            : 'https://roobeek-dev.systrategy.com'
        ,CLIENTE_COD            : 7
        ,NUM_RECORDPAGE         : 500
        ,DEBUG                  : true
        ,...common
    },
    dev: {
        REACT_APP_CLIENT_ID    : '3kmq78ti8dmnkurnmvsqiqfesl'
        ,REACT_APP_USERPOOL_ID  : 'eu-west-3_eomwt9wCx'
        ,API_URL                : 'https://ilsole24ore.api.systrategy.com/dis24'
        ,ROOBEEK_URL            : 'https://roobeek-dev.systrategy.com'
        ,CLIENTE_COD            : 7
        ,NUM_RECORDPAGE         : 500
        ,DEBUG                  : true
        ,...common
    },
    demo: {
         REACT_APP_CLIENT_ID    : '45kj8phi875l5l28puivhi871n'
        ,REACT_APP_USERPOOL_ID  : 'eu-west-3_FAxOH4Zeb'
        ,API_URL                : 'https://ilsole24ore.api.systrategy.com/dis24'
        ,ROOBEEK_URL            : 'https://ilsole24ore.roobeek.systrategy.com'
        ,GAM_ACCOUNT_CODE       : '57491254'
        ,CLIENTE_COD            : 10
        ,NUM_RECORDPAGE         : 500
        ,DEBUG                  : true
        ,...common
    },
    localdisc: {
         REACT_APP_CLIENT_ID    : '3kmq78ti8dmnkurnmvsqiqfesl'
        ,REACT_APP_USERPOOL_ID  : 'eu-west-3_eomwt9wCx'
        ,API_URL                : 'https://api.wbdiscovery.systrategy.com/digitalanalyzer'
        ,ROOBEEK_URL            : 'https://roobeek.wbdiscovery.systrategy.com'
        ,CLIENTE_COD            : 7
        ,NUM_RECORDPAGE         : 500
        ,DEBUG                  : true
        ,...common
    },
    proddisc: {
        REACT_APP_CLIENT_ID    : '3kmq78ti8dmnkurnmvsqiqfesl'
        ,REACT_APP_USERPOOL_ID  : 'eu-west-3_eomwt9wCx'
        ,API_URL                : 'https://api.wbdiscovery.systrategy.com/digitalanalyzer'
        ,ROOBEEK_URL            : 'https://roobeek.wbdiscovery.systrategy.com'
        ,CLIENTE_COD            : 7
        ,NUM_RECORDPAGE         : 500
        ,DEBUG                  : false
        ,...common
    },
    localsole: {
         REACT_APP_CLIENT_ID    : '45kj8phi875l5l28puivhi871n'
        ,REACT_APP_USERPOOL_ID  : 'eu-west-3_FAxOH4Zeb'
        ,API_URL                : 'https://ilsole24ore.api.systrategy.com/dis24'
        ,ROOBEEK_URL            : 'https://ilsole24ore.roobeek.systrategy.com'
        ,GAM_ACCOUNT_CODE       : '57491254'
        ,CLIENTE_COD            : 10
        ,NUM_RECORDPAGE         : 500
        ,DEBUG                  : true
        ,...common
    },
    prodsole: {
         REACT_APP_CLIENT_ID    : '45kj8phi875l5l28puivhi871n'
        ,REACT_APP_USERPOOL_ID  : 'eu-west-3_FAxOH4Zeb'
        ,API_URL                : 'https://ilsole24ore.api.systrategy.com/dis24'
        ,ROOBEEK_URL            : 'https://ilsole24ore.roobeek.systrategy.com'
        ,GAM_ACCOUNT_CODE       : '57491254'
        ,CLIENTE_COD            : 10
        ,NUM_RECORDPAGE         : 500
        ,DEBUG                  : false
        ,...common
    }
};

export default environment[ process.env.REACT_APP_ENV || 'dev' ];
